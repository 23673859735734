<template>
    <div id="showList" style="float:left;width:60%;border: 0px solid black;">
        <div style="float:left;width: 98%;">
            <label for="dropdown"  style="float:left;text-align: left;width:200px;font-size: 18px;margin: 0px 20px 20px 30px;">Choose a customer</label>
            <select id="dropdown" style="float:left;height:30px;width:500px;font-size: 18px;" v-model="selectedCust"  @change="handleCustChange">
                <option v-for="optionC in optionsCust" :key="optionC.prtId" :value="optionC.prtId">
                    {{ optionC.prtId }} : {{ optionC.prtName }}
                </option>
            </select>

        </div>
        <br/>
        <div style="float:left;width: 98%;">
            <label for="dropdown"  style="float:left;text-align: left;width:200px;font-size: 18px;margin: 0px 20px 20px 30px;">Choose a service</label>
            <select id="dropdown" style="float:left;height:30px;width:500px;font-size: 18px;" v-model="selectedServ"  @change="handlePayTypeChange">
                <option v-for="optionS in optionsServ" :key="optionS.payType" :value="optionS.payType">
                    {{ optionS.payType }} : {{ optionS.payDescr }}</option>
            </select>
        </div>
        <ag-grid-vue
            class="ag-theme-alpine"
            style="float:left;height: 50vh; width: 950px; margin-left: 10px;"
            :columnDefs="columnDefs"
            :rowData="rowData"
            @cellDoubleClicked="onCellDoubleClicked"
            :defaultColDef="{ cellClassRules: cellClassRules }"
            :overlayLoadingTemplate="overlayLoadingTemplate"
            :overlayNoRowsTemplate="overlayNoRowsTemplate"
        >
        </ag-grid-vue>        
        <!-- this hould be inside the grid-vue :rowSelection="rowSelection" -->
        <div style="float:left;height:60px;width:99%;margin:20px 0 0 0;">
            <div style="float:left;height:55px;width:380px;margin:-5px 0px 0px 10px;border-radius: 20px;border: 0px solid black;">
                <input id="pswd" v-model="userPass" class="lyt_input" type="password" style="border-radius: 10px;line-height:30px;margin:12px 0 0 25px;width:160px" placeholder=" Enter your password" required/>
                <button class='btn' style="margin-top:4px;width:150px;float:left" @click="requestKey">RequestKey</button>
            </div>
            <button class='btn' style="margin-left:100px;width:150px;float:left" @click="refreshData">Refresh</button>
        </div>
        <div style="float:left;width:99%;">            
            <p id='errMsg' style="color:red;text-align:left;padding:0px;margin:10px 0px 0px 15px">&nbsp;</p>
            <p style="text-align:left;padding:0px;margin:15px 0px 0px 15px">Note</p>
            <p style="text-align:left;padding:0px;margin:0px 0px 0px 15px">Enter your password and click on RequestKey to get authorization key emailed to your registered email account</p>
            <p style="text-align:left;padding:0px;margin:0px 0px 0px 15px">Double click on transfer line to select transfer</p>
            <p style="text-align:left;padding:0px;margin:0px 0px 0px 15px">Please do not use the browser refresh or you will be logged out. Use the application refresh above instead</p>
        </div>
    </div>
    <div id='showAppr' style="width:60%" hidden>      
        <div>
            <div class="lyt_div">
                <label for="ourRef" class="lyt_label" style="width:15%" >Our Reference</label>
                <input id="ourRef" v-model="apprData.ourRef"  type="text" class="lyt_input" style="width:45%;padding-left:10px;" disabled required/>
            </div>
        </div>   
        <br/>     
        <div>
            <div class="lyt_div">
                <label for="bankName" class="lyt_label" style="width:15%">Bank Name</label>
                <input id="bankName" v-model="apprData.bankName" type="text" class="lyt_input" style="width:45%;padding-left:10px;" disabled required/>
            </div>
        </div>        
        <div>
            <div class="lyt_div">
                <label for="servCode" class="lyt_label" style="width:15%" >Service Code</label>
                <input id="servCode" v-model="apprData.servCode" type="text" class="lyt_input" style="width:45%;padding-left:10px;" disabled required/>
            </div>
        </div>        
        <div>
            <div class="lyt_div">
                <label for="submitDate" class="lyt_label" style="width:15%" >Submit Date</label>
                <input id="submitDate" v-model="apprData.submitDate" type="text" class="lyt_input" style="width:45%;padding-left:10px;" disabled required/>
            </div>
        </div>      
        <div>
            <div class="lyt_div">
                <label for="trxStatName" class="lyt_label" style="width:15%" >Status</label>
                <input id="trxStatName" v-model="apprData.trxStatName" type="text" class="lyt_input" style="width:45%;padding-left:10px;" disabled required/>
            </div>
        </div>  
        <div class="lyt_div">
            <label for="totAmt" class="lyt_label" style="width:15%" >Amount</label>
            <input id="totAmt" v-model="apprData.totAmt" type="text" class="lyt_input" style="width:45%;padding-left:10px;" disabled required/>
        </div>
        <div class="lyt_div">
            <label for="totCnt" class="lyt_label" style="width:15%" >Nos Trx</label>
            <input id="totCnt" v-model="apprData.totCnt" type="text" class="lyt_input" style="width:45%;padding-left:10px;" disabled required/>
        </div>
        <div class="lyt_div">
            <label for="authKey" class="lyt_label" style="width:15%">AuthKey</label>
            <input id="authKey" v-model="authKey" class="lyt_input" style="padding-left:10px;width:45%;" type="password" placeholder="Enter auth key" required/>
        </div>
        <!-- <br/><br/><br/><br/><br/><br/><br/><br/> -->
        <div style="float:left;height:50px;margin:20px 0 0 0">
            <button class='btn' style="width:150px;margin:25px 0 0 270px;" @click="approveTrx" :disabled="isAppr">Approve</button>
            <button class='btn' style="width:150px;" @click="closeAppr">Close</button>
        </div>
        <p id='errMsg2' style="color:red;text-align:left;padding:0px;margin:10px 0px 0px 15px">&nbsp;</p>
    </div>





</template>

<script>
    import { useUserStore } from '../stores/userStore'
    // import { AgGridVue } from 'ag-grid-vue'
    import { AgGridVue } from 'ag-grid-vue3'
    import 'ag-grid-community/styles/ag-grid.css'
    import 'ag-grid-community/styles/ag-theme-alpine.css'
    // import TreeView from '../components/TreeView.vue'

    export default {
        name: 'MgrTrxAppr', 
        components:{
            AgGridVue, 
            // TreeView
        },
        data() {
            return {
                isAppr: false, 
                selectedCust: null,
                selectedServ: null,
                optionsCust: [],
                optionsServ: [],

                isPasswordVisible: false,
                // isPasswordVisible: true,
                userPass: "",
                loginData: {
                    customerCode:'',
                    serviceCode: ''
                },
                formTitle : "Approve Transfer",
                columnDefs:[
                    {headerName:'Our Ref',      field:'ourRef',     width:120, cellStyle: { textAlign: 'left' }},
                    {headerName:'Status',       field:'trxStatName',width:100, cellStyle: { textAlign: 'left' }, filter:true},
                    {headerName:'Bank Name',    field:'bankName',   width:200, cellStyle: { textAlign: 'left' }},
                    {headerName:'Serv.Code',    field:'servCode',   width:100, cellStyle: { textAlign: 'left' }},
                    {headerName:'SubmitDate',   field:'submitDate', width:120, cellStyle: { textAlign: 'left' }},
                    {headerName:'Total Amount', field:'totAmt',     width:150, cellStyle: { textAlign: 'right' }, valueFormatter: this.currencyFormatter},
                    {headerName:'Nos Trx',      field:'totCnt',     width:150, cellStyle: { textAlign: 'right' }, valueFormatter: this.countFormatter}
                    // {headerName:'Action',       field:'action',     width:200, cellStyle: { textAlign: 'center' }}
                ],

                rowData:null,

                overlayLoadingTemplate: '<span style="font-size:20px;color:Red">Please choose a customer & service type</span>', // Custom loading overlay
                overlayNoRowsTemplate:  '<span style="font-size:20px;color:Blue">No records found</span>', // Custom no rows overlay
      
                authKey:'',
                
                apprData:{
                    ourRef:'',
                    trxStatName:'',
                    bankName:'',
                    servCode:'',
                    submitDate:'',
                    totAmt:'',
                    totCnt:''
                },
                onCellDoubleClicked: event => {
                    document.getElementById("showList").style.display = "none"
                    document.getElementById("showAppr").style.display = "block"
                    this.apprData.ourRef = event.data.ourRef
                    this.apprData.bankName = event.data.bankName
                    this.apprData.servCode = event.data.servCode
                    this.apprData.submitDate = event.data.submitDate
                    this.apprData.trxStatName = event.data.trxStatName
                    this.apprData.totAmt = event.data.totAmt.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                    this.apprData.totCnt = event.data.totCnt.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})
                    this.authKey=''
                    this.isAppr=false
                }
            }
        },
        created() {            
        },
        mounted() {            
            document.getElementById("formTitle").innerHTML = "Approve Transfer"
            const curUser = useUserStore()
            if ((curUser.name=='') || (curUser.secLevel > 1)) {
                this.$router.push({ name: 'Login'})
            } else {
                this.getApprList()
            }
        },
        
        computed: {
            cellClassRules() {
                return {
                    'toPay': params => params.value === 'Pay'
                };
            },
            selectedCustName() {
                const selected = this.optionsCust.find(option => option.prtId === this.selectedCust);
            return selected ? selected.prtName : '';
            },
            selectedServName() {
                const selected = this.optionsServ.find(option => option.payType === this.selectedServ);
            return selected ? selected.payDescr : '';
            },
        },

        methods: {
            async getApprList() {
                const curUser = useUserStore()
                const host = curUser.apiServer
                const param =   { method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        'userid':curUser.name
                                    }                                            
                                }
                const response = await fetch(host+'/getApprList', param);
                const reply = await response.json();
                const custList = reply[0].cust
                const servList = reply[0].serv
                this.optionsCust = custList.map(item => ({
                    prtId: item.prtId,
                    prtName: item.prtName
                })); 
                this.optionsServ = servList.map(item => ({
                    payType: item.cde,
                    payDescr: item.descr,
                }));


            },

            async handleCustChange() {
                if ((this.selectedCust != null) && (this.selectedServ != null)) {
                    this.fetchData()
                }
            },

            async handlePayTypeChange() {
                if ((this.selectedCust != null) && (this.selectedServ != null)) {
                    this.fetchData()
                }
            },

            closeAppr() {
                document.getElementById("showList").style.display = "block"
                document.getElementById("showAppr").style.display = "none"
                this.fetchData()
            },
            async refreshData() {
                document.getElementById("errMsg").style.display = "none"
                this.fetchData()
            },

            async fetchData() {
                if ((this.selectedCust != null) && (this.selectedServ != null)) {
                    const curUser = useUserStore()
                    const host = curUser.apiServer
                    this.loginData.customerCode = this.selectedCust
                    this.loginData.serviceCode = this.selectedServ
                    if ((curUser.name!='') && (curUser.secLevel ==1)) {
                        try {
                            const param = { method: 'POST',
                                            headers: {'Content-Type': 'application/json',
                                                'usr':curUser.name
                                            },
                                            body: JSON.stringify(this.loginData)
                                        }
                            const response = await fetch(host+'/trxGetAppr', param);
                            this.rowData = await response.json();
                        } catch (error) {
                            console.error('Error fetching data', error);
                        }
                    } else {
                        this.$router.push({ name: 'Login'})
                    }
                } else {                    
                    document.getElementById('errMsg').innerHTML = "Information: Please select a service type"  
                    document.getElementById("errMsg").style.color = "Red" 
                    document.getElementById("errMsg").style.display = "block"
                }
            },

            async requestKey() {
                const curUser = useUserStore()   
                const host = curUser.apiServer                  
                document.getElementById('errMsg').innerHTML = ""
                document.getElementById("errMsg").style.display = "none"
                if (this.loginData.password == "") {
                    throw new Error('You need to key in your password');
                } else {
                    try {
                        if (this.userPass!=='') {
                            const param = { method: 'POST',
                                            headers: {'Content-Type': 'application/json',
                                                    'usr':curUser.name,
                                                    'pwd':this.userPass
                                                },
                                            body: JSON.stringify(this.loginData)
                                        }
                            const response = await fetch(host+'/apprkey', param);
                            if (!response.ok) {
                                throw new Error('Network response was not ok ' + response.statusText);
                            }
                            const data = await response.json();
                            if (data[0].Response == 'SUCCESS') {
                                document.getElementById('errMsg').innerHTML = "Information: Please check your registered email for approval key code"  
                                document.getElementById("errMsg").style.color = "Blue" 
                                document.getElementById("errMsg").style.display = "block"
                            } else {                           
                                document.getElementById('errMsg').innerHTML = "WARNING: "+data[0].key
                                document.getElementById("errMsg").style.display = "block"
                            }
                        } else {
                            document.getElementById('errMsg').innerHTML = "WARNING: You need to enter your password before requesting for approval key"
                            document.getElementById("errMsg").style.display = "block"
                        }
                    } catch (error) {
                        console.error('There was a problem with the fetch operation:', error);
                    }
                }
            },
            
            async approveTrx() {
                const confirmed = confirm("Are you sure you want to approve the transaction?");
                if (confirmed) {
                    this.isAppr=true
                    this.approveTrxOK()
                }

            },
                        
            async approveTrxOK() {
                const curUser = useUserStore() 
                const host = curUser.apiServer   
                if ((curUser.name!='') && (curUser.secLevel ==1)) {
                    const url   = host+'/trxPostAppr/'+this.apprData.ourRef
                    const param =   {   method: 'POST',
                                        headers: {
                                            'Content-Type': 'application/json',
                                            'userid': curUser.name,
                                            'authKey': this.authKey
                                        },
                                        body: JSON.stringify(this.apprData)
                                    }
                    try {                       
                        const response = await fetch(url, param);
                        if (!response.ok) {
                            throw new Error(`HTTP error! Status: ${response.status}`);
                        } else {                    
                            const rowData = await response.json();
                            document.getElementById('errMsg').style.display = 'block'
                            if (rowData.Response=='SUCCESS') {
                                document.getElementById('errMsg').style.color = 'Blue'
                            } else {
                                document.getElementById('errMsg').style.color = 'Red'
                            }
                            document.getElementById('errMsg').innerHTML = rowData.OurRef + ": " + rowData.Remarks

                            document.getElementById("showList").style.display = "block"
                            document.getElementById("showAppr").style.display = "none"
                            this.fetchData()                        
                        }
                    } catch (error) {
                        console.error('Error fetching data', error);
                    }
                } else {
                    this.$router.push({ name: 'Login'})
                }
            },

            logoutSession() {
                const curUser = useUserStore()
                curUser.clearUser()
                this.$router.push({ name: 'Login'})
            },

            currencyFormatter(params) {
                // Format salary to include '$' symbol and thousand separator
                // return params.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                // return params.value.toFixed(2);
                return params.value.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})


            },
            
            countFormatter(params) {
                // Format salary to include '$' symbol and thousand separator
                // return params.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                return params.value.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})
            },


        }

    }
</script>

<style>
    .toPay {
    background-color: lightgreen; /* Change this to the color you prefer */
}

    /* .lyt_div { 
        min-Width:500px;
        float: none;
        line-height: 40px;
        margin-left: 10px;
    } */

    button {
        font-size:15px;
        color: white;
        background: #047857;
        margin: 0 10px;
        padding: 15px;
        border: none;
        border-radius: 20px;
    }

    .btn:focus {
    outline: 1px solid black;
    }

    .btn:hover {
    color: white;
    background-color: #47980E;
    background-image: linear-gradient(#ecf0f1 ,#47980E);
    }

</style>