<!-- components/Footer.vue -->

<template>
    <footer class="footer">
      <div class="container">
        <span class="text-muted">
          Copyright blan blah blah 2024
        </span>
      </div>
    </footer>
  </template>
  
  <script>
  export default {
    name: 'Footer'
  };
  </script>
  
  <style scoped>
  .footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: lightgray;
    text-align: center;
    padding: 10px 0;
    /* Adjust styles as needed */
  }
  </style>
  