import { createRouter, createWebHashHistory } from 'vue-router'
import Login from '../views/Login.vue'
import Home from '../views/Home.vue'
import TrxStfAmd from '../views/TrxStfAmd.vue'
import TrxMgrApp from '../views/TrxMgrApp.vue'
import TrxMgrView from '../views/TrxMgrView.vue'
import BnkSetup from '../views/BnkSetup.vue'
import BnkAmend from '../views/BnkAmend.vue'
import CstAmend from '../views/CstAmend.vue'
import UsrAmend from '../views/UsrAmend.vue'
import SetupCust from '../views/StpCust.vue'
import SetupCustNav from '../views/StpCustNav.vue'
import SetParam from '../views/SetParam.vue'

const routes = [
  { path: '/',           name: 'Login',      component: Login,       meta: {hideMenu:true}  },
  { path: '/home',       name: 'Home',       component: Home },
  { path: '/trxStfAmd',  name: 'TrxStfAmd',  component: TrxStfAmd },
  { path: '/trxMgrApp',  name: 'TrxMgrApp',  component: TrxMgrApp },
  { path: '/trxMgrView', name: 'TrxMgrView', component: TrxMgrView },
  { path: '/bnkStp',     name: 'BnkSetup',   component: BnkSetup },
  { path: '/bnkAmend',   name: 'BnkAmend',   component: BnkAmend },
  { path: '/cstAmend',   name: 'CstAmend',   component: CstAmend },
  { path: '/usrAmend',   name: 'UsrAmend',   component: UsrAmend },
  { path: '/stpCust',    name: 'StpCust',    component: SetupCust },
  { path: '/stpCustNav', name: 'StpCustNav', component: SetupCustNav },
  { path: '/SetParam',   name: 'SetParam',   component: SetParam },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

export default router
