<template>
  <div style="float:left;margin:20px 0 0 70px;width:700px;border: 0px solid black;">      
        <div>
            <div class="lyt_div">
                <label for="fName" class="lyt_label">Customer Name</label>
                <input id="fName" v-model="cstInfo.name" type="text" class="lyt_input" required/>
            </div>
        </div>        
        <div>
            <div class="lyt_div">
                <label for="short" class="lyt_label">Short Name</label>
                <input id="short" v-model="cstInfo.short" type="text" class="lyt_input" required/>
            </div>
        </div>        
        <div>
            <div class="lyt_div">
                <label for="contact" class="lyt_label">Contact Person</label>
                <input id="contact" v-model="cstInfo.contact" type="text" class="lyt_input" required/>
            </div>
        </div>    
        <div>
            <div class="lyt_div">
                <label for="mobile" class="lyt_label">Contact Mobile</label>
                <input id="mobile" v-model="cstInfo.mobile" type="text" class="lyt_input" />
            </div>
        </div>    
        <div>
            <div class="lyt_div">
                <label for="tel" class="lyt_label">Contact Telephone</label>
                <input id="tel" v-model="cstInfo.tel" type="text" class="lyt_input" />
            </div>
        </div>  
        <div>
            <div class="lyt_div">
                <label for="email" class="lyt_label">Contact Email</label>
                <input id="email" v-model="cstInfo.email" type="text" class="lyt_input" required/>
            </div>
        </div>  
    </div>
    <div style="float:left;margin:20px 0 0 90px;width:65%;border: 0px solid black;">
        <p id='errMsg' style="float:left;color:red;text-align:left;padding:0px;margin:10px 0px 20px 15px">&nbsp;</p>
    </div>
    <div style="float:left;width:65%; text-align: left; border: 0px solid black;">
        <button class='btn' style="width:150px;margin-left:18%" @click="submitNewBank">Submit</button>
    </div>
</template>

<script>
    import { useUserStore } from '../stores/userStore'

    export default {
        name:'BankSetup',
        data() {
            return {
                cstInfo: {
                    name: '',
                    short:'',
                    contact:'',
                    mobile:'',
                    tel:'',
                    email:'',
                },
            }
        },
        mounted() {            
            document.getElementById("formTitle").innerHTML = "Setup New Customer Information"
            const curUser = useUserStore()
            if ((curUser.name=='') || (curUser.secLevel > 1)) {
                this.$router.push({ name: 'Login'})
            }
        },
        methods: {
            async submitNewBank() {
                if (( this.cstInfo.name != '') &&
                    ( this.cstInfo.short != '') &&
                    ( this.cstInfo.contact != '') &&
                    ( this.cstInfo.mobile != '') &&
                    ( this.cstInfo.tel != '') &&
                    ( this.cstInfo.email != '')) {
                    const curUser = useUserStore() 
                    const host = curUser.apiServer   
                    if ((curUser.name!='') && (curUser.secLevel <=1)) {
                        const url   = host+'/stpBnkNew'
                        const param =   {   method: 'POST',
                                            headers: {
                                                'Content-Type': 'application/json',
                                                'userid': curUser.name
                                            },
                                            body: JSON.stringify(this.cstInfo)
                                        }
                        try {                       
                            const response = await fetch(url, param);
                            if (!response.ok) {
                                throw new Error(`HTTP error! Status: ${response.status}`);
                            } else {                    
                                const rowData = await response.json();
                                document.getElementById('errMsg').style.display = 'block'
                                if (rowData.Response=='SUCCESS') {
                                    document.getElementById('errMsg').style.color = 'Blue'
                                    this.cstInfo.name = ''
                                    this.cstInfo.short = ''
                                    this.cstInfo.contact = ''
                                    this.cstInfo.mobile = ''
                                    this.cstInfo.tel = ''
                                    this.cstInfo.email = ''
                                } else {
                                    document.getElementById('errMsg').style.color = 'Red'
                                }
                                document.getElementById('errMsg').innerHTML = rowData.Msg
                            }
                        } catch (error) {
                            console.error('Error fetching data', error);
                        }
                    } else {
                        this.$router.push({ name: 'Login'})
                    }
                } else {

                    
                }
            },
        }


    }
</script>

<style>

</style>