<template>
    <div id="showList" style="float:left;width:80%;border: 0px solid black;">
        <div style="float:left;width: 79%;">
            <label for="dropdown"  style="float:left;font-size: 18px;width:180px;margin: 5px 20px 20px 20px;">Select a customer:</label>
            <select id="dropdown" style="float:left;height:30px;width:500px;font-size: 18px;" v-model="selectedCust"  @change="handleCustChange">
                <option v-for="optionC in optionsCust" :key="optionC.prtId" :value="optionC.prtId">
                    {{ optionC.prtId }} {{ optionC.prtName }}
                </option>
            </select>
            
            <!-- <div style="border: 1px solid black;" v-if="selectedCust">
                <label style="float:left;font-size: 18px;margin: 0px 20px 20px 20px;">Customer Name</label>
                <p>{{ selectedCustName }}</p>
            </div> -->

        </div>
        <ag-grid-vue
            class="ag-theme-alpine"
            style="float:left;height: 50vh; width: 100%; margin-left: 10px;"
            :columnDefs="columnDefs"
            :rowData="rowData"
            @cellDoubleClicked="onCellDoubleClicked"
            :defaultColDef="{ cellClassRules: cellClassRules }"
            :overlayLoadingTemplate="overlayLoadingTemplate"
            :overlayNoRowsTemplate="overlayNoRowsTemplate"
        >
        </ag-grid-vue>        
        <!-- this hould be inside the grid-vue :rowSelection="rowSelection" -->
        <div style="float:left;height:60px;width:99%;margin:20px 0 0 0;">
            <button class='btn' style="margin-left:100px;width:150px;float:left" @click="addData">Add</button>
            <button class='btn' style="margin-left:10px;width:150px;float:left" @click="refreshData">Refresh</button>
        </div>
        <div style="float:left;width:99%;">            
            <p id='errMsg' style="color:red;text-align:left;padding:0px;margin:10px 0px 0px 15px">&nbsp;</p>
            <p style="text-align:left;padding:0px;margin:15px 0px 0px 15px">Note</p>
            <p style="text-align:left;padding:0px;margin:0px 0px 0px 15px">Double click on navigation line to amend</p>
            <p style="text-align:left;padding:0px;margin:0px 0px 0px 15px">Please do not use the browser refresh or you will be logged out. Use the application refresh above instead</p>
        </div>
    </div>
    <!-- entry/amend navigation -->
    <div id='showDetails' style="float:left;width:650px;border: 0px solid black;" hidden> 
        <div class="lyt_div" hidden>
            <label for="prtId" class="lyt_label" style="width:125px" >PrtId</label>
            <input id="prtId" v-model="detData.prtId"  type="text" class="lyt_input" style="width:400px;padding-left:10px;" disabled required/>
        </div>
        <br/>
        <div class="lyt_div">
            <label for="prtName" class="lyt_label" style="float:left;width:125px" >Partner Name</label>
            <input id="prtName" v-model="detData.prtName"  type="text" class="lyt_input" style="width:400px;padding-left:10px;" disabled/>
        </div>
        <div style="height:8px"><br/></div>
        <div v-if="addReq">
            <div class="lyt_div" style="border: 0px solid black;">
                <label for="bnkId" class="lyt_label" style="float:left;height:26px;line-height: 26px;width:125px;margin:0 2px 0 15px;padding-left:3px;">Bank Name</label>
                <select id="bnkId" style="float:left;width:428px;font-size: 18px;height:30px;line-height: 30px;margin-left:4px;padding-left:6px;" v-model="selectedBank"  @change="handleBankChange">
                    <option v-for="optionB in optionsBank" :key="optionB.prtId" :value="optionB.prtId">
                        {{ optionB.prtName }}
                    </option>
                </select>
            </div>
            <div style="height:8px"><br/></div>

            <div class="lyt_div" style="border: 0px solid black;">
                <label for="servCode" class="lyt_label" style="float:left;height:26px;line-height: 26px;width:125;margin:0 2px 0 15px;padding-left:3px;">Service Code</label>
                <select id="servCode" style="float:left;width:428px;font-size: 18px;height:30px;line-height: 30px;margin-left:2px;padding-left:2px;" v-model="selectedServ"  @change="handleBankChange">
                    <option v-for="optionC in optionsServ" :key="optionC.payType" :value="optionC.payType">
                        {{ optionC.payType }}
                    </option>
                </select>
            </div>
            <div style="height:8px"><br/></div>
            <div class="lyt_div" style="margin-bottom:10px;border: 0px solid black;">
                <label for="effDate" class="lyt_label" style="float:left;height:30px;line-height: 30px;;width:125px;margin:0 2px 0 15px;padding-left:3px" >Effective Date</label>
                <input id="effDate" v-model="detData.effDate" type="date" class="lyt_input" style="height:30px;line-height: 30px;width:400px;margin-left:5px;padding-left:10px;" required/>
            </div>
        </div>

        <div v-else>
            <div class="lyt_div">
                <label for="bnkId" class="lyt_label" style="width:125px">Bank Id</label>
                <input id="bnkId" v-model="detData.bnkId" type="text" class="lyt_input" style="width:400px;padding-left:10px;" disabled required/>
            </div>
            <div class="lyt_div">
                <label for="bankName" class="lyt_label" style="width:125px">Bank Name</label>
                <input id="bankName" v-model="detData.bnkName" type="text" class="lyt_input" style="width:400px;padding-left:10px;" disabled required/>
            </div> 
            <div class="lyt_div" style="padding-top:-10px">
                <!-- <label for="servCode" class="lyt_label" style="width:125px" >Service Code</label> -->
                <label for="servCode" class="lyt_label">Service Code</label>
                <input id="servCode" v-model="detData.payType" type="text" class="lyt_input" style="width:400px;padding-left:10px;margin-bottom:5px;" disabled required/>
            </div>
            <div class="lyt_div" style="margin-bottom:10px;border: 0px solid black;">
                <label for="effDate" class="lyt_label" style="float:left;height:30px;line-height: 30px;;width:125px;margin:0 2px 0 15px;padding-left:3px" >Effective Date</label>
                <input id="effDate" v-model="detData.effDate" type="date" class="lyt_input" style="height:30px;line-height: 30px;width:400px;margin-left:5px;padding-left:10px;" disabled/>
            </div>
        </div>  
        
        <div class="lyt_div">
            <label for="bnkAcct" class="lyt_label" style="width:125px" >Bank Acct</label>
            <input id="bnkAcct" v-model="detData.acct" type="text" class="lyt_input" @input="formatCardNumber" placeholder="1234 5678 9012 3456" style="width:400px;padding-left:10px;" required/>
        </div>

        <div class="lyt_div">
            <label class="lyt_label" style="margin-top:20px;font-size:20px;font-weight: bold;width:90%" >Bank SFTP Configuration</label>
        </div>
        
        <div class="lyt_div">
            <label for="bnkhost" class="lyt_label" style="width:125px" >Host</label>
            <input id="bnkhost" v-model="detData.host" type="text" class="lyt_input" style="width:400px;padding-left:10px;" required/>
        </div>
            
        <div style="height:8px"><br/></div>
        <br/>
            <div class="lyt_div">
                <label for="bnkPort" class="lyt_label" style="width:125px" >Port</label>
                <input id="bnkPort" v-model="detData.port" type="text" class="lyt_input" style="width:400px;padding-left:10px;" required/>
            </div>
            <div style="height:8px"><br/></div>
            <div class="lyt_div">
                <label for="bnkUser" class="lyt_label" style="width:125px" >User</label>
                <input id="bnkUser" v-model="detData.username" type="text" class="lyt_input" style="width:400px;padding-left:10px;" required/>
            </div>
            <div style="height:8px"><br/></div>
            <div class="lyt_div">
                <label for="bnkPswd" class="lyt_label" style="width:125px" >Password</label>
                <input id="bnkPswd" v-model="detData.password" type="password" class="lyt_input" style="width:400px;padding-left:10px;" required/>
            </div>
            <div style="height:8px"><br/></div>
            <div class="lyt_div">
                <label for="bnkInput" class="lyt_label" style="width:125px" >Input path</label>
                <input id="bnkInput" v-model="detData.sftpInput" type="text" class="lyt_input" style="width:400px;padding-left:10px;" required/>
            </div>
            <div style="height:8px"><br/></div>
            <div class="lyt_div">
                <label for="bnkOutput" class="lyt_label" style="width:125px" >Output path</label>
                <input id="bnkOutput" v-model="detData.sftpOutput" type="text" class="lyt_input" style="width:400px;padding-left:10px;" required/>
            </div>
        
        <div style="float:left;height:50px;width:600px;padding:20px 0 0 80px;border: 0px solid black;">
            <button class='btn' style="width:125px;margin-left:0px;" @click="saveUpdate">Save</button>
            <button class='btn' style="width:125px;margin-left:0px;" @click="deleteNav">Delete</button>
            <button class='btn' style="width:125px;margin-left:0px;" @click="closeAppr">Close</button>
        </div>
        <p id='errMsg2' style="color:red;text-align:left;padding:0px;margin:10px 0px 0px 15px">&nbsp;</p>
    </div>





</template>

<script>
    import { useUserStore } from '../stores/userStore'
    import { dateYMD } from '../util/util'
    // import { AgGridVue } from 'ag-grid-vue'
    import { AgGridVue } from 'ag-grid-vue3'
    import 'ag-grid-community/styles/ag-grid.css'
    import 'ag-grid-community/styles/ag-theme-alpine.css'
    // import TreeView from '../components/TreeView.vue'

    export default {
        name: 'stpCustNav', 
        components:{
            AgGridVue, dateYMD
            // TreeView
        },
        data() {
            return {
                selectedCust: null,
                selectedBank: null,
                selectedServ: null,
                optionsCust: [],
                optionsBank: [],
                optionsServ: [],
                addReq : false,
                bnkLst:false,
                isPasswordVisible: false,
                // isPasswordVisible: true,
                userPass: "",
                loginData: {
                    customerCode:''
                },
                formTitle : "Update Customer Navigation",
                
                rowData:null,

                overlayLoadingTemplate: '<span style="font-size:20px;color:Red">Please choose a service type</span>', // Custom loading overlay
                overlayNoRowsTemplate:  '<span style="font-size:20px;color:Blue">No records found</span>', // Custom no rows overlay
      
                authKey:'',

                columnDefs:[
                    {headerName:'PrtId',        field:'prtId',      cellStyle: { textAlign: 'left' }, width:80, hide: true},
                    {headerName:'Partner Name', field:'prtName',    cellStyle: { textAlign: 'left' }, width:80, hide: true},
                    {headerName:'BankId',       field:'bnkId',      cellStyle: { textAlign: 'left' }, width:80 },
                    {headerName:'Bank Name',    field:'bnkName',    cellStyle: { textAlign: 'left' }, width:300},
                    {headerName:'SrvCode',      field:'payType',    cellStyle: { textAlign: 'left' }, width:100},
                    {headerName:'EffDate',      field:'effDate',    cellStyle: { textAlign: 'left' }, width:125, valueFormatter: this.dateFormatter},
                    {headerName:'Acct',         field:'acct',       cellStyle: { textAlign: 'left' }, width:170},
                    {headerName:'Host',         field:'host',       cellStyle: { textAlign: 'left' }, width:140},
                    {headerName:'Port',         field:'port',       cellStyle: { textAlign: 'left' }, width:80 },
                    {headerName:'UserName',     field:'username',   cellStyle: { textAlign: 'left' }, width:150},
                    {headerName:'Password',     field:'password',   cellStyle: { textAlign: 'left' }, width:150, hide: true},
                    {headerName:'SFTP Input',   field:'sftpInput',  cellStyle: { textAlign: 'left' }, width:300},
                    {headerName:'SFTP Output',  field:'sftpOutput', cellStyle: { textAlign: 'left' }, width:300}
                ],
                
                detData:{
                    prtId:'',
                    prtName:'',
                    bnkId:'',
                    bnkName:'',
                    payType:'',
                    effDate:'',
                    acct:'',
                    host:'',
                    port:'',
                    username:'',
                    password:'',
                    sftpInput:'',
                    sftpOutput:''
                },
                delData:{
                    prtId:'',
                    bnkId:'',
                    payType:'',
                    effDate:'',
                },
                onCellDoubleClicked: event => {
                    document.getElementById("showList").style.display = "none"
                    document.getElementById("showDetails").style.display = "block"
                    this.detData.prtId = event.data.prtId
                    this.detData.prtName = event.data.prtName
                    this.detData.bnkId = event.data.bnkId
                    this.detData.bnkName = event.data.bnkName
                    this.detData.payType = event.data.payType
                    this.detData.effDate = dateYMD(event.data.effDate)
                    this.detData.acct = event.data.acct
                    this.detData.host = event.data.host
                    this.detData.port = event.data.port
                    this.detData.username = event.data.username
                    this.detData.password = event.data.password
                    this.detData.sftpInput = event.data.sftpInput
                    this.detData.sftpOutput = event.data.sftpOutput
                    this.addReq = false
                    
                    // document.getElementById("prtId").style.display = "block"
                }
            }
        },

        created() {            
        },

        mounted() {            
            document.getElementById("formTitle").innerHTML = this.formTitle
            const curUser = useUserStore()
            if ((curUser.name=='') || (curUser.secLevel > 1)) {
                this.$router.push({ name: 'Login'})
            } else {
                this.getStpNavList()
            }
        },
        
        computed: {
            cellClassRules() {
                return {
                    'toPay': params => params.value === 'Pay'
                }
            },
            selectedCustName() {
                const selected = this.optionsCust.find(option => option.prtId === this.selectedCust);
                return selected ? selected.prtName : '';
            },
            selectedBankName() {
                const selected = this.options.find(option => option.prtName === this.selectedOption);
                return selected ? selected.prtName : '';
            },
        },

        methods: {
            async getStpNavList() {
                const curUser = useUserStore()
                const host = curUser.apiServer
                const param =   { method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        'userid':curUser.name
                                    }                                            
                                }
                const response = await fetch(host+'/getStpNavList', param);
                const reply = await response.json();
                const custList = reply[0].cust
                const bankList = reply[0].bank
                const servList = reply[0].serv
                this.optionsCust = custList.map(item => ({
                    prtId: item.prtId,
                    prtName: item.prtName
                }));
                this.optionsBank = bankList.map(item => ({
                    prtId: item.prtId,
                    prtName: item.prtName
                }));                
                this.optionsServ = servList.map(item => ({
                    payType: item.cde
                }));
            },

            async handleCustChange() {
                this.fetchData()
            },

            closeAppr() {
                document.getElementById("showList").style.display = "block"
                document.getElementById("showDetails").style.display = "none"
                this.fetchData()
            },
            async refreshData() {
                document.getElementById("errMsg").style.display = "none"
                this.fetchData()
            },

            async fetchData() {
                if (this.selectedCust != '') {
                    // document.getElementById("errMsg").style.display = "none"
                    const curUser = useUserStore()
                    const host = curUser.apiServer
                    if ((curUser.name!='') && (curUser.secLevel ==1)) {
                        try {
                            const param = { method: 'POST',
                                            headers: {'Content-Type': 'application/json',
                                                'usr':curUser.name,
                                                'cst':this.selectedCust
                                            },
                                        }
                            const response = await fetch(host+'/trxGetNav', param);
                            this.rowData = await response.json();
                        } catch (error) {
                            console.error('Error fetching data', error);
                        }
                    } else {
                        this.$router.push({ name: 'Login'})
                    }
                } else {                    
                    document.getElementById('errMsg').innerHTML = "Information: Please select a service type"  
                    document.getElementById("errMsg").style.color = "Red" 
                    document.getElementById("errMsg").style.display = "block"
                }
            },

            async addData() {
                if (this.selectedCust) {
                    this.addReq = true
                    document.getElementById("showList").style.display = "none"
                    document.getElementById("showDetails").style.display = "block"
                    this.detData.prtId = this.selectedCust
                    this.detData.prtName = this.selectedCustName
                    this.detData.bnkId = ""
                    this.detData.bnkName = ""
                    this.detData.payType = ""
                    this.detData.effDate = ""
                    this.detData.acct = ""
                    this.detData.host = ""
                    this.detData.port = ""
                    this.detData.username = ""
                    this.detData.password = ""
                    this.detData.sftpInput = ""
                    this.detData.sftpOutput = ""
                    
                    // document.getElementById("prtId").style.display = "block"
                } else {                    
                    document.getElementById('errMsg').style.display = 'block'
                    document.getElementById('errMsg').style.color = 'Red'
                    document.getElementById('errMsg').innerHTML = "Please select a customer before trying to add"

                }
            },

            async saveUpdate() {
                const curUser = useUserStore() 
                const host = curUser.apiServer   
                if (this.addReq) {
                    this.detData.bnkId = this.selectedBank
                    this.detData.payType = this.selectedServ
                }

                if ((curUser.name!='') && (curUser.secLevel ==1)) {
                    const url   = host+'/navSave'
                    const param =   {   method: 'POST',
                                        headers: {
                                            'Content-Type': 'application/json',
                                            'usr': curUser.name
                                        },
                                        body: JSON.stringify(this.detData)
                                    }
                    try {                       
                        const response = await fetch(url, param);
                        if (!response.ok) {
                            throw new Error(`HTTP error! Status: ${response.status}`);
                        } else {                    
                            const rowData = await response.json();
                            document.getElementById('errMsg').style.display = 'block'
                            if (rowData.ok) {
                                document.getElementById('errMsg').style.color = 'Blue'
                            } else {
                                document.getElementById('errMsg').style.color = 'Red'
                            }
                            document.getElementById('errMsg').innerHTML = rowData.msg

                            document.getElementById("showList").style.display = "block"
                            document.getElementById("showDetails").style.display = "none"
                            this.fetchData()                        
                        }
                    } catch (error) {
                        console.error('Error saving data', error);
                    }
                } else {
                    this.$router.push({ name: 'Login'})
                }
            },
            
            async deleteNav() {
                const curUser = useUserStore() 
                const host = curUser.apiServer   
                this.delData.prtId = this.detData.prtId
                this.delData.bnkId = this.detData.bnkId
                this.delData.payType = this.detData.payType
                this.delData.effDate = this.detData.effDate


                if ((curUser.name!='') && (curUser.secLevel ==1)) {
                    const url   = host+'/navDel'
                    const param =   {   method: 'POST',
                                        headers: {
                                            'Content-Type': 'application/json',
                                            'usr': curUser.name
                                        },
                                        body: JSON.stringify(this.delData)
                                    }
                    try {                       
                        const response = await fetch(url, param);
                        if (!response.ok) {
                            throw new Error(`HTTP error! Status: ${response.status}`);
                        } else {                    
                            const rowData = await response.json();
                            console.log(rowData)
                            document.getElementById('errMsg').style.display = 'block'
                            if (rowData.Response=='SUCCESS') {
                                document.getElementById('errMsg').style.color = 'Blue'
                            } else {
                                document.getElementById('errMsg').style.color = 'Red'
                            }
                            document.getElementById('errMsg').innerHTML = rowData.Msg

                            document.getElementById("showList").style.display = "block"
                            document.getElementById("showDetails").style.display = "none"
                            this.fetchData()                        
                        }
                    } catch (error) {
                        console.error('Error saving data', error);
                    }
                } else {
                    this.$router.push({ name: 'Login'})
                }
            },
            
            dateFormatter(params) {
                if (params.value) {
                    const date = new Date(params.value);
                    const day = String(date.getDate()).padStart(2, '0');
                    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                    const year = date.getFullYear();
                    return `${day}-${month}-${year}`;
                }
            },

            formatCardNumber() {
            this.detData.acct = this.detData.acct
                .replace(/\D/g, '') // Remove all non-digit characters
                .replace(/(.{4})/g, '$1 ') // Add a space every 4 digits
                .trim(); // Remove trailing spaces
            },
        }

    }
</script>

<style>
    .toPay {
    background-color: lightgreen; /* Change this to the color you prefer */
}

    /* .lyt_div { 
        min-Width:500px;
        float: none;
        line-height: 40px;
        margin-left: 10px;
    } */

    button {
        font-size:15px;
        color: white;
        background: #047857;
        margin: 0 10px;
        padding: 15px;
        border: none;
        border-radius: 20px;
    }

    .btn:focus {
    outline: 1px solid black;
    }

    .btn:hover {
    color: white;
    background-color: #47980E;
    background-image: linear-gradient(#ecf0f1 ,#47980E);
    }

</style>