<template>
    <div id="showList" style="float:left;width:80%;border: 0px solid black;">
        
        <ag-grid-vue
            class="ag-theme-alpine"
            style="float:left;height: 50vh; width: 100%; margin-left: 10px;"
            :columnDefs="columnDefs"
            :rowData="rowData"
            @cellDoubleClicked="onCellDoubleClicked"
            :defaultColDef="{ cellClassRules: cellClassRules }"
        >
        </ag-grid-vue>        
        <!-- this hould be inside the grid-vue :rowSelection="rowSelection" -->
        <div style="float:left;height:60px;width:99%;margin:20px 0 0 0;">
            <button class='btn' style="margin-left:100px;width:150px;float:left" @click="addData">Add</button>
            <button class='btn' style="margin-left:10px;width:150px;float:left" @click="refreshData">Refresh</button>
            <button class='btn' style="margin-left:10px;width:150px;float:left" @click="undeleteUserList">Undelete</button>
        </div>
        <div style="float:left;width:99%;">            
            <p id='errMsg' style="color:red;text-align:left;padding:0px;margin:10px 0px 0px 15px">&nbsp;</p>
            <p style="text-align:left;padding:0px;margin:15px 0px 0px 15px">Note</p>
            <p style="text-align:left;padding:0px;margin:0px 0px 0px 15px">Double click on navigation line to amend</p>
            <p style="text-align:left;padding:0px;margin:0px 0px 0px 15px">Please do not use the browser refresh or you will be logged out. Use the application refresh above instead</p>
        </div>
    </div>

    <!-- entry/amend navigation -->
    <div id='showDetails' style="float:left;width:650px;border: 0px solid black;" hidden> 
        <div class="lyt_div">
            <label class="lyt_label" style="margin-top:20px;font-size:20px;font-weight: bold;width:90%" >Access Information</label>
        </div>
        <div style="height:8px"><br/></div>

        <div v-if="addReq">
            <div class="lyt_div">
                <label for="username" class="lyt_label" style="width:125px" >User Id</label>
                <input id="username" v-model="detData.username"  type="text" class="lyt_input" style="width:400px;padding-left:10px;" />
            </div>
        </div>
        <div v-else>
            <div class="lyt_div">
                <label for="username" class="lyt_label" style="width:125px" >User Id</label>
                <input id="username" v-model="detData.username"  type="text" class="lyt_input" style="width:400px;padding-left:10px;" disabled/>
            </div>
        </div>
        <div style="height:8px"><br/></div>

        <div class="lyt_div">
            <label for="password" class="lyt_label" style="float:left;width:125px" >Password</label>
            <input id="password" v-model="detData.password"  type="password" class="lyt_input" style="width:400px;padding-left:10px;" required/>
        </div>
        <div style="height:8px"><br/></div>

        <div class="lyt_div">
            <label for="secLevel" class="lyt_label" style="float:left;width:125px" >Security Level</label>
            <input id="secLevel" v-model="detData.secLevel"  type="text" class="lyt_input" style="width:400px;padding-left:10px;" required/>
        </div>
        <div style="height:8px"><br/></div>
                
        <div class="lyt_div">
            <label class="lyt_label" style="margin-top:20px;font-size:20px;font-weight: bold;width:90%" >Personal Details</label>
        </div>

        <div class="lyt_div">
            <label for="firstName" class="lyt_label" style="float:left;width:125px" >First Name</label>
            <input id="firstName" v-model="detData.firstName"  type="text" class="lyt_input" style="width:400px;padding-left:10px;" required/>
        </div>        
        <div style="height:8px"><br/></div>

        <div class="lyt_div">
            <label for="LastName" class="lyt_label" style="float:left;width:125px" >LastName Name</label>
            <input id="LastName" v-model="detData.lastName"  type="text" class="lyt_input" style="width:400px;padding-left:10px;" required/>
        </div>
        <div style="height:8px"><br/></div>

        <div class="lyt_div">
            <label for="fullName" class="lyt_label" style="float:left;width:125px" >Full Name</label>
            <input id="fullName" v-model="detData.fullName"  type="text" class="lyt_input" style="width:400px;padding-left:10px;" required/>
        </div>
        <div style="height:8px"><br/></div>

        <div class="lyt_div" style="border: 0px solid black;">
            <label for="prtId" class="lyt_label" style="float:left;height:26px;line-height: 26px;width:125px;margin:0 2px 0 15px;padding-left:3px;">Customer Name</label>
            <select id="prtId" style="float:left;width:428px;font-size: 18px;height:30px;line-height: 30px;margin-left:4px;padding-left:6px;" v-model="selectedCust"  @change="handleCustChange">
                <option v-for="optionC in optionsCust" :key="optionC.prtId" :value="optionC.prtId">
                    {{ optionC.prtId }} : {{ optionC.prtName }}
                </option>
            </select>
        </div>
        <div style="height:8px"><br/></div>
<!-- 
        <div class="lyt_div">
            <label for="prtId" class="lyt_label" style="width:125px" >Company Id</label>
            <input id="prtId" v-model="detData.prtId" type="text" class="lyt_input" style="width:400px;padding-left:10px;" required/>
        </div>
        <div style="height:8px"><br/></div>

        <div class="lyt_div">
            <label for="prtName" class="lyt_label" style="width:125px" >Company Name</label>
            <input id="prtName" v-model="detData.prtName" type="text" class="lyt_input" style="width:400px;padding-left:10px;" required/>
        </div>
        <div style="height:8px"><br/></div> -->

        <div class="lyt_div">
            <label for="email" class="lyt_label" style="width:125px" >Email</label>
            <input id="email" v-model="detData.email" type="text" class="lyt_input" style="width:400px;padding-left:10px;" required/>
        </div>
        <div style="height:8px"><br/></div>
        
        
        <div style="float:left;height:50px;width:600px;padding:20px 0 0 80px;border: 0px solid black;">
            <button class='btn' style="width:125px;margin-left:0px;" @click="saveUpdate">Save</button>
            <button v-if="mde==-1" class='btn' style="width:125px;margin-left:0px;" @click="undeleteUser">Undelete</button>
            <button v-else class='btn' style="width:125px;margin-left:0px;" @click="deleteUser">Delete</button>
            <button class='btn' style="width:125px;margin-left:0px;" @click="closeAppr">Close</button>
        </div>
        <p id='errMsg2' style="color:red;text-align:left;padding:0px;margin:10px 0px 0px 15px">&nbsp;</p>

        <div>
            
  </div>


    </div>





</template>

<script>
    import { useUserStore } from '../stores/userStore'
    import { dateYMD } from '../util/util'
    import { AgGridVue } from 'ag-grid-vue3'
    import 'ag-grid-community/styles/ag-grid.css'
    import 'ag-grid-community/styles/ag-theme-alpine.css'

    export default {
        name: 'usrAmend', 
        components:{
            AgGridVue, dateYMD
        },
        data() {
            return {
                selectedCust: null,
                optionsCust: [],
                addReq : false,
                mde:1,
                bnkLst:false,
                userPass: "",                
                rowData:null,

                // overlayLoadingTemplate: '<span style="font-size:20px;color:Red">Please choose a service type</span>', // Custom loading overlay
                // overlayNoRowsTemplate:  '<span style="font-size:20px;color:Blue">No records found</span>', // Custom no rows overlay
      
                authKey:'',

                columnDefs:[
                    {headerName:'User Name',  field:'username',  cellStyle: { textAlign: 'left' }, width:150},
                    {headerName:'First Name', field:'firstName', cellStyle: { textAlign: 'left' }, width:150},
                    {headerName:'Last Name',  field:'lastName',  cellStyle: { textAlign: 'left' }, width:150 },
                    {headerName:'Full Name',  field:'fullName',  cellStyle: { textAlign: 'left' }, width:250},
                    {headerName:'Password',   field:'password',  cellStyle: { textAlign: 'left' }, width:150, hide: true},
                    {headerName:'Sec.',       field:'secLevel',  cellStyle: { textAlign: 'center' }, width:80},
                    {headerName:'CustId',     field:'prtId',     cellStyle: { textAlign: 'left' }, width:100},
                    {headerName:'CustName',   field:'prtName',   cellStyle: { textAlign: 'left' }, width:200},
                    {headerName:'eMail',      field:'email',     cellStyle: { textAlign: 'left' }, width:200},
                ],
                
                detData:{
                    username:'',
                    firstName:'',
                    lastName:'',
                    fullName:'',
                    password:'',
                    secLevel:'',
                    prtId:'',
                    prtName:'',
                    email:'',
                },

                onCellDoubleClicked: event => {
                    document.getElementById("showList").style.display = "none"
                    document.getElementById("showDetails").style.display = "block"
                    this.detData.username     = event.data.username
                    this.detData.firstName = event.data.firstName
                    this.detData.lastName  = event.data.lastName
                    this.detData.fullName  = event.data.fullName
                    this.detData.password  = event.data.password
                    this.detData.secLevel  = event.data.secLevel
                    this.detData.prtId     = event.data.prtId
                    this.detData.prtName   = event.data.prtName
                    this.detData.email     = event.data.email
                    this.addReq = false
                    
                    this.selectedCust = this.detData.prtId
                    
                    // document.getElementById("prtId").style.display = "block"
                }
            }
        },

        created() {            
        },

        mounted() {            
            document.getElementById("formTitle").innerHTML = "User Information"
            const curUser = useUserStore()
            if ((curUser.name=='') || (curUser.secLevel > 1)) {
                this.$router.push({ name: 'Login'})
            } else {
                this.mde=1
                this.getStpList()
                this.fetchData()
            }
        },
        
        computed: {
            selectedCustName() {
                const selected = this.optionsCust.find(option => option.prtId === this.selectedCust);
                return selected ? selected.prtName : '';
            },
        },

        methods: {
            async getStpList() {
                const curUser = useUserStore()
                const host = curUser.apiServer
                const param =   { method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        'userid':curUser.name
                                    }                                            
                                }
                const response = await fetch(host+'/getCstList', param);
                const reply = await response.json();
                console.log('cust list',reply[0])
                const custList = reply[0].cust
                this.optionsCust = custList.map(item => ({
                    prtId: item.prtId,
                    prtName: item.prtName
                }));
            },

            async handleCustChange() {
                this.fetchData()
            },

            closeAppr() {
                document.getElementById("showList").style.display = "block"
                document.getElementById("showDetails").style.display = "none"
                this.addReq = false
                this.mde=1
                this.fetchData()
            },

            async refreshData() {
                document.getElementById("errMsg").style.display = "none"
                this.mde=1
                this.fetchData()
            },

            async undeleteUserList() {
                this.mde = -1
                document.getElementById("errMsg").style.display = "none"
                this.fetchData()
            },

            async fetchData() {
                // document.getElementById("errMsg").style.display = "none"
                const curUser = useUserStore()
                const host = curUser.apiServer
                if ((curUser.name!='') && (curUser.secLevel ==1)) {
                    try {
                        const param = { method: 'POST',
                                        headers: {'Content-Type': 'application/json',
                                            'usr':curUser.name,
                                            'mde':this.mde
                                        },
                                    }
                        const response = await fetch(host+'/usrAmd', param);
                        // console.log('response',response)
                        const rowData = await response.json();
                        // console.log('rowData',rowData)
                        if (rowData.Response) {
                            this.rowData = rowData.Msg
                        } else {                                     
                            document.getElementById('errMsg').style.display = 'block'
                            document.getElementById('errMsg').style.color = 'Red'
                            document.getElementById('errMsg').innerHTML = "Error retrieving User information"
                        }

                    } catch (error) {
                        console.error('Error fetching data', error);
                    }
                } else {
                    this.$router.push({ name: 'Login'})
                }
            },
            
            
            async addData() {
                const confirmed = confirm("Are you sure you want to create new User?");
                if (confirmed) {
                    this.addDataOK()
                }
            },

            async addDataOK() {
                    this.addReq = true
                    document.getElementById("showList").style.display = "none"
                    document.getElementById("showDetails").style.display = "block"
                    this.detData.prtId = "NEW"
                    this.detData.prtClass = "BK"
                    this.detData.prtName = ""
                    this.detData.prtShort= ""
                    this.detData.prtContact = ""
                    this.detData.prtTel = ""
                    this.detData.prtMobile = ""
                    this.detData.prtEmail = ""
                    this.detData.prtStatus = "1"         
                    console.log(this.detData)           
                    // document.getElementById("prtId").style.display = "block"
            },

                       
            async undeleteUser() {
                const confirmed = confirm("Are you sure you want to undelete the user information?");
                if (confirmed) {
                    this.undeleteUserOk()
                }
            },

            async undeleteUserOk() {
                const curUser = useUserStore() 
                const host = curUser.apiServer 

                if ((curUser.name!='') && (curUser.secLevel ==1)) {
                    const url   = host+'/usrUndel'
                    const param =   {   method: 'POST',
                                        headers: {
                                            'Content-Type': 'application/json',
                                            'usr': curUser.name,
                                            'usrnme': this.detData.username
                                        },
                                    }
                    try {                       
                        const response = await fetch(url, param);
                        if (!response.ok) {
                            throw new Error(`HTTP error! Status: ${response.status}`);
                        } else {                    
                            const rowData = await response.json();
                            console.log(rowData)
                            document.getElementById('errMsg').style.display = 'block'
                            if (rowData.Response=='SUCCESS') {
                                document.getElementById('errMsg').style.color = 'Blue'
                            } else {
                                document.getElementById('errMsg').style.color = 'Red'
                            }
                            document.getElementById('errMsg').innerHTML = rowData.Msg

                            document.getElementById("showList").style.display = "block"
                            document.getElementById("showDetails").style.display = "none"
                            this.mde=1
                            this.fetchData()                        
                        }
                    } catch (error) {
                        console.error('Error saving data', error);
                    }
                } else {
                    this.$router.push({ name: 'Login'})
                }
            },

            async saveUpdate() {
                const confirmed = confirm("Are you sure you want to save the user information?");
                if (confirmed) {
                    this.saveUpdateOK()
                }
            },

            async saveUpdateOK() {
                const curUser = useUserStore() 
                const host = curUser.apiServer   
                this.detData.prtId = this.selectedCust
                this.detData.prtName = this.selectedCustName

                console.log('detData',JSON.stringify(this.detData))
                if ((curUser.name!='') && (curUser.secLevel ==1)) {
                    const url   = host+'/usrSave'
                    const param =   {   method: 'POST',
                                        headers: {
                                            'Content-Type': 'application/json',
                                            'usr': curUser.name
                                        },
                                        body: JSON.stringify(this.detData)
                                    }
                    try {                       
                        const response = await fetch(url, param);
                        console.log('response ',response)
                        if (!response.ok) {
                            throw new Error(`HTTP error! Status: ${response.status}`);
                        } else {          
                            const rowData = await response.json();
                            console.log(rowData)
                            document.getElementById('errMsg').style.display = 'block'
                            if (rowData.Response=="SUCCESS") {
                                document.getElementById('errMsg').style.color = 'Blue'
                            } else {
                                document.getElementById('errMsg').style.color = 'Red'
                            }
                            document.getElementById('errMsg').innerHTML = rowData.Msg

                            document.getElementById("showList").style.display = "block"
                            document.getElementById("showDetails").style.display = "none"
                            this.fetchData()                        
                        }
                    } catch (error) {
                        console.error('Error saving data', error);
                    }
                } else {
                    this.$router.push({ name: 'Login'})
                }
            },
            
            async deleteUser() {
                const confirmed = confirm("Are you sure you want to delete the user information?");
                if (confirmed) {
                    this.deleteUserOk()
                }
            },

            async deleteUserOk() {
                const curUser = useUserStore() 
                const host = curUser.apiServer 

                if ((curUser.name!='') && (curUser.secLevel ==1)) {
                    const url   = host+'/usrDel'
                    const param =   {   method: 'POST',
                                        headers: {
                                            'Content-Type': 'application/json',
                                            'usr': curUser.name,
                                            'usrnme': this.detData.username
                                        },
                                    }
                    try {                       
                        const response = await fetch(url, param);
                        if (!response.ok) {
                            throw new Error(`HTTP error! Status: ${response.status}`);
                        } else {                    
                            const rowData = await response.json();
                            console.log(rowData)
                            document.getElementById('errMsg').style.display = 'block'
                            if (rowData.Response=='SUCCESS') {
                                document.getElementById('errMsg').style.color = 'Blue'
                            } else {
                                document.getElementById('errMsg').style.color = 'Red'
                            }
                            document.getElementById('errMsg').innerHTML = rowData.Msg

                            document.getElementById("showList").style.display = "block"
                            document.getElementById("showDetails").style.display = "none"
                            this.fetchData()                        
                        }
                    } catch (error) {
                        console.error('Error saving data', error);
                    }
                } else {
                    this.$router.push({ name: 'Login'})
                }
            },
            
            closeAppr() {
                document.getElementById("showList").style.display = "block"
                document.getElementById("showDetails").style.display = "none"
                this.addReq = false
                this.mde=1
                this.fetchData()
            },
            
            dateFormatter(params) {
                if (params.value) {
                    const date = new Date(params.value);
                    const day = String(date.getDate()).padStart(2, '0');
                    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                    const year = date.getFullYear();
                    return `${day}-${month}-${year}`;
                }
            },

            formatCardNumber() {
            this.detData.acct = this.detData.acct
                .replace(/\D/g, '') // Remove all non-digit characters
                .replace(/(.{4})/g, '$1 ') // Add a space every 4 digits
                .trim(); // Remove trailing spaces
            },
        }

    }
</script>

<style>

    /* .lyt_div { 
        min-Width:500px;
        float: none;
        line-height: 40px;
        margin-left: 10px;
    } */

    button {
        font-size:15px;
        color: white;
        background: #047857;
        margin: 0 10px;
        padding: 15px;
        border: none;
        border-radius: 20px;
    }

    .btn:focus {
    outline: 1px solid black;
    }

    .btn:hover {
    color: white;
    background-color: #47980E;
    background-image: linear-gradient(#ecf0f1 ,#47980E);
    }

</style>