<template>
  <ul>
    <TreeItem
      class="item"
      v-for="child in treeData.children"
      :key="child.name"
      :model="child"
      :depth="1">
    </TreeItem>
  </ul>
</template>

<script setup>
import { ref } from 'vue'
import TreeItem from './TreeItem.vue'
import { useUserStore } from '../stores/userStore'

    const treeData = ref({
      name: 'My Tree',
      children: [
        { name: 'Home', to: 'Home'},
        { name: 'Transaction', 
            children: [
            {name: 'Amend', to: 'TrxStfAmd' , children : []},
            {name: 'Approve', to: 'TrxMgrApp' , children : []},
            {name: 'View', to: 'TrxMgrView' , children : []},
            ] 
        },
        
        { name: 'Setup', 
            children: [
            {name: 'Bank',
                children: [
                    { name:'Information',to: 'BnkAmend'},   
                  ]
              },
              {name: 'Customer',
                children: [
                    { name:'Information',to: 'CstAmend'},
                    { name:'Navigation',to: 'StpCustNav'}      
                  ]
              },
              {name: 'User',
                children: [
                    { name:'Information',to: 'UsrAmend'},     
                  ]
              }
            ]
        },  
        { name: 'Settings', 
            children: [
              {name: 'Parameters',to: 'SetParam'},
            ] 
        }, 
        { name: 'Logout', to: 'Login'  }, 
      ]
    })
</script>

<!-- <script>
  export default {
    props: {
      data: {
        type: Object,
        required: true
      }
    },
    methods: {
      onNodeClick(node) {
        this.$emit('node-click', node);
      }
    }
};

</script> -->

<style scoped>
.item {
  font-size:25px;
  cursor: pointer;
  line-height: 1.5;
}
.bold {
  font-weight: bold;
}
</style>
