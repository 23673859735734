import { defineStore } from 'pinia'

export const useUserStore = defineStore('userStore', {
  state: () => ({
        name: '',
        loginTime:'',
        cryptKey:'',
        secLevel : 99,
        // apiServer:'http://109.123.234.116:3000',
        apiServer:'https://api.tzoetc.com',
        payType:"",
        prtId:""
  }),
  actions: {
    setUser(newUser,cryptKey,loginTime,secLevel,prtId) {
      this.name=newUser
      this.cryptKey=cryptKey
      this.loginTime=loginTime
      this.secLevel=secLevel
      this.prtId=prtId
    },
    clearUser() {
      this.name=''
      this.cryptKey=''
      this.secLevel=99
      this.loginTime=''
    },
  },
})

// export const useUserStore = defineStore('userStore', {
//   state: () => ({
//     message: '',
//   }),
//   actions: {
//     setMessage(newMessage) {
//       this.message = newMessage;
//     },
//   },
// });
