<template>
    <div id="showList" style="float:left;width:80%;border: 0px solid black;">
        
        <ag-grid-vue
            class="ag-theme-alpine"
            style="float:left;height: 50vh; width: 100%; margin-left: 10px;"
            :columnDefs="columnDefs"
            :rowData="rowData"
            @cellDoubleClicked="onCellDoubleClicked"
            :defaultColDef="{ cellClassRules: cellClassRules }"
        >
        </ag-grid-vue>        
        <!-- this hould be inside the grid-vue :rowSelection="rowSelection" -->
        <div style="float:left;height:60px;width:99%;margin:20px 0 0 0;">
            <button class='btn' style="margin-left:100px;width:150px;float:left" @click="addData">Add</button>
            <button class='btn' style="margin-left:10px;width:150px;float:left" @click="refreshData">Refresh</button>
            <button class='btn' style="margin-left:10px;width:150px;float:left" @click="undeleteCust">Undelete</button>
        </div>
        <div style="float:left;width:99%;">            
            <p id='errMsg' style="color:red;text-align:left;padding:0px;margin:10px 0px 0px 15px">&nbsp;</p>
            <p style="text-align:left;padding:0px;margin:15px 0px 0px 15px">Note</p>
            <p style="text-align:left;padding:0px;margin:0px 0px 0px 15px">Double click on navigation line to amend</p>
            <p style="text-align:left;padding:0px;margin:0px 0px 0px 15px">Please do not use the browser refresh or you will be logged out. Use the application refresh above instead</p>
        </div>
    </div>
    <!-- entry/amend navigation -->
    <div id='showDetails' style="float:left;width:650px;border: 0px solid black;" hidden> 
        <div class="lyt_div">
            <label for="prtId" class="lyt_label" style="width:125px" >Customer Id</label>
            <input id="prtId" v-model="detData.prtId"  type="text" class="lyt_input" style="width:400px;padding-left:10px;" disabled/>
        </div>
        <br/>
        <div class="lyt_div">
            <label for="prtName" class="lyt_label" style="float:left;width:125px" >Customer Name</label>
            <input id="prtName" v-model="detData.prtName"  type="text" class="lyt_input" style="width:400px;padding-left:10px;" required/>
        </div>
        <div style="height:8px"><br/></div>
        <div class="lyt_div">
            <label for="prtShort" class="lyt_label" style="float:left;width:125px" >Short Name</label>
            <input id="prtShort" v-model="detData.prtShort"  type="text" class="lyt_input" style="width:400px;padding-left:10px;" required/>
        </div>
        <div style="height:8px"><br/></div>
                
        <div class="lyt_div">
            <label class="lyt_label" style="margin-top:20px;font-size:20px;font-weight: bold;width:90%" >Contact Details</label>
        </div>
        
        <div class="lyt_div">
            <label for="prtContact" class="lyt_label" style="width:125px" >Name</label>
            <input id="prtContact" v-model="detData.prtContact" type="text" class="lyt_input" style="width:400px;padding-left:10px;" required/>
        </div>
        
        <div class="lyt_div">
            <label for="prtTel" class="lyt_label" style="width:125px" >Telephone</label>
            <input id="prtTel" v-model="detData.prtTel" type="text" class="lyt_input" style="width:400px;padding-left:10px;" required/>
        </div>
            
        <div style="height:8px"><br/></div>
        <br/>
            <div class="lyt_div">
                <label for="prtMobile" class="lyt_label" style="width:125px" >Mobile</label>
                <input id="prtMobile" v-model="detData.prtMobile" type="text" class="lyt_input" style="width:400px;padding-left:10px;" required/>
            </div>
            <div style="height:8px"><br/></div>
            <div class="lyt_div">
                <label for="prtEmail" class="lyt_label" style="width:125px" >Email</label>
                <input id="prtEmail" v-model="detData.prtEmail" type="text" class="lyt_input" style="width:400px;padding-left:10px;" required/>
            </div>
            <div style="height:8px"><br/></div>
            <div class="lyt_div" hidden>
                <label for="prtStatus" class="lyt_label" style="width:125px" >Status</label>
                <input id="prtStatus" v-model="detData.prtStatus" type="password" class="lyt_input" style="width:400px;padding-left:10px;" required/>
            </div>
        
        <div style="float:left;height:50px;width:600px;padding:20px 0 0 80px;border: 0px solid black;">
            <button class='btn' style="width:125px;margin-left:0px;" @click="saveUpdate">Save</button>
            <button v-if="mde==-1" class='btn' style="width:125px;margin-left:0px;" @click="undeletePartner">Undelete</button>
            <button v-else class='btn' style="width:125px;margin-left:0px;" @click="deletePartner">Delete</button>
            <button class='btn' style="width:125px;margin-left:0px;" @click="closeAppr">Close</button>
        </div>
        <p id='errMsg2' style="color:red;text-align:left;padding:0px;margin:10px 0px 0px 15px">&nbsp;</p>

        <div>
            
  </div>


    </div>





</template>

<script>
    import { useUserStore } from '../stores/userStore'
    import { dateYMD } from '../util/util'
    import { AgGridVue } from 'ag-grid-vue3'
    import 'ag-grid-community/styles/ag-grid.css'
    import 'ag-grid-community/styles/ag-theme-alpine.css'

    export default {
        name: 'stpCustNav', 
        components:{
            AgGridVue, dateYMD
            // TreeView
        },
        data() {
            return {
                addReq : false,
                mde:1,
                bnkLst:false,
                isPasswordVisible: false,
                // isPasswordVisible: true,
                userPass: "",
                loginData: {
                    customerCode:''
                },
                formTitle : "Customer Information",
                
                rowData:null,

                authKey:'',

                columnDefs:[
                    {headerName:'Id',           field:'prtId',      cellStyle: { textAlign: 'left' }, width:80},
                    {headerName:'Name',         field:'prtName',    cellStyle: { textAlign: 'left' }, width:300},
                    {headerName:'Short',        field:'prtShort',   cellStyle: { textAlign: 'left' }, width:120 },
                    {headerName:'Contact',      field:'prtContact', cellStyle: { textAlign: 'left' }, width:250},
                    {headerName:'Telephone',    field:'prtTel',     cellStyle: { textAlign: 'left' }, width:150},
                    {headerName:'Mobile',       field:'prtMobile',  cellStyle: { textAlign: 'left' }, width:150},
                    {headerName:'eMail',        field:'prtEmail',   cellStyle: { textAlign: 'left' }, width:200},
                    {headerName:'Status',       field:'prtStatus',  cellStyle: { textAlign: 'left' }, width:140, hide: true},
                ],
                
                detData:{
                    prtId:'',
                    prtClass:'',
                    prtName:'',
                    prtShort:'',
                    prtContact:'',
                    prtTel:'',
                    prtMobile:'',
                    prtEmail:'',
                    prtStatus:'',
                },
                onCellDoubleClicked: event => {
                    document.getElementById("showList").style.display = "none"
                    document.getElementById("showDetails").style.display = "block"
                    this.detData.prtId      = event.data.prtId
                    this.detData.prtClass   = event.data.prtClass
                    this.detData.prtName    = event.data.prtName
                    this.detData.prtShort   = event.data.prtShort
                    this.detData.prtContact = event.data.prtContact
                    this.detData.prtTel     = event.data.prtTel
                    this.detData.prtMobile  = event.data.prtMobile
                    this.detData.prtEmail   = event.data.prtEmail
                    this.detData.prtStatus  = event.data.prtStatus
                    this.addReq = false
                    
                    // document.getElementById("prtId").style.display = "block"
                }
            }
        },

        created() {            
        },

        mounted() {            
            document.getElementById("formTitle").innerHTML = this.formTitle
            const curUser = useUserStore()
            if ((curUser.name=='') || (curUser.secLevel > 1)) {
                this.$router.push({ name: 'Login'})
            } else {
                this.mde=1
                this.fetchData()
            }
        },
        
        computed: {
            cellClassRules() {
                return {
                    'toPay': params => params.value === 'Pay'
                };
            },
        },

        methods: {
           
            closeAppr() {
                document.getElementById("showList").style.display = "block"
                document.getElementById("showDetails").style.display = "none"
                this.addReq = false
                this.mde=1
                this.fetchData()
            },

            async refreshData() {
                document.getElementById("errMsg").style.display = "none"
                this.mde=1
                this.fetchData()
            },

            async undeleteCust() {
                this.mde = -1
                document.getElementById("errMsg").style.display = "none"
                this.fetchData()
            },

            async fetchData() {
                // document.getElementById("errMsg").style.display = "none"
                const curUser = useUserStore()
                const host = curUser.apiServer
                if ((curUser.name!='') && (curUser.secLevel ==1)) {
                    try {
                        const param = { method: 'POST',
                                        headers: {'Content-Type': 'application/json',
                                            'usr':curUser.name,
                                            'mde':this.mde
                                        },
                                    }
                        const response = await fetch(host+'/cstAmd', param);
                        // console.log('response',response)
                        const rowData = await response.json();
                        // console.log('rowData',rowData)
                        if (rowData.Response) {
                            this.rowData = rowData.Msg
                        } else {                                     
                            document.getElementById('errMsg').style.display = 'block'
                            document.getElementById('errMsg').style.color = 'Red'
                            document.getElementById('errMsg').innerHTML = "Error retrieving bank information"
                        }

                    } catch (error) {
                        console.error('Error fetching data', error);
                    }
                } else {
                    this.$router.push({ name: 'Login'})
                }
            },
            
            
            async addData() {
                const confirmed = confirm("Are you sure you want to create new bank?");
                if (confirmed) {
                    this.addDataOK()
                }
            },

            async addDataOK() {
                    this.addReq = true
                    document.getElementById("showList").style.display = "none"
                    document.getElementById("showDetails").style.display = "block"
                    this.detData.prtId = "NEW"
                    this.detData.prtClass = "CO"
                    this.detData.prtName = ""
                    this.detData.prtShort= ""
                    this.detData.prtContact = ""
                    this.detData.prtTel = ""
                    this.detData.prtMobile = ""
                    this.detData.prtEmail = ""
                    this.detData.prtStatus = "1"         
                    console.log(this.detData)           
                    // document.getElementById("prtId").style.display = "block"
            },

            async saveUpdate() {
                const confirmed = confirm("Are you sure you want to save the bank information?");
                if (confirmed) {
                    this.saveUpdateOK()
                }
            },

            async saveUpdateOK() {
                const curUser = useUserStore() 
                const host = curUser.apiServer   
                console.log('detData',JSON.stringify(this.detData))
                if ((curUser.name!='') && (curUser.secLevel ==1)) {
                    const url   = host+'/prtSave'
                    const param =   {   method: 'POST',
                                        headers: {
                                            'Content-Type': 'application/json',
                                            'usr': curUser.name
                                        },
                                        body: JSON.stringify(this.detData)
                                    }
                    try {                       
                        const response = await fetch(url, param);
                        console.log('response ',response)
                        if (!response.ok) {
                            throw new Error(`HTTP error! Status: ${response.status}`);
                        } else {          
                            const rowData = await response.json();
                            console.log(rowData)
                            document.getElementById('errMsg').style.display = 'block'
                            if (rowData.Response=="SUCCESS") {
                                document.getElementById('errMsg').style.color = 'Blue'
                            } else {
                                document.getElementById('errMsg').style.color = 'Red'
                            }
                            document.getElementById('errMsg').innerHTML = rowData.Msg

                            document.getElementById("showList").style.display = "block"
                            document.getElementById("showDetails").style.display = "none"
                            this.fetchData()                        
                        }
                    } catch (error) {
                        console.error('Error saving data', error);
                    }
                } else {
                    this.$router.push({ name: 'Login'})
                }
            },
            
            
            async undeletePartner() {
                const confirmed = confirm("Are you sure you want to undelete the bank information?");
                if (confirmed) {
                    this.undeletePartnerOk()
                }
            },

            async undeletePartnerOk() {
                const curUser = useUserStore() 
                const host = curUser.apiServer 
                this.detData.bnkId = this.selectedBank
                this.detData.payType = this.selectedServ

                if ((curUser.name!='') && (curUser.secLevel ==1)) {
                    const url   = host+'/prtUndel'
                    const param =   {   method: 'POST',
                                        headers: {
                                            'Content-Type': 'application/json',
                                            'usr': curUser.name,
                                            'prt': this.detData.prtId
                                        },
                                    }
                    try {                       
                        const response = await fetch(url, param);
                        if (!response.ok) {
                            throw new Error(`HTTP error! Status: ${response.status}`);
                        } else {                    
                            const rowData = await response.json();
                            console.log(rowData)
                            document.getElementById('errMsg').style.display = 'block'
                            if (rowData.Response=='SUCCESS') {
                                document.getElementById('errMsg').style.color = 'Blue'
                            } else {
                                document.getElementById('errMsg').style.color = 'Red'
                            }
                            document.getElementById('errMsg').innerHTML = rowData.Msg

                            document.getElementById("showList").style.display = "block"
                            document.getElementById("showDetails").style.display = "none"
                            this.mde=1
                            this.fetchData()                        
                        }
                    } catch (error) {
                        console.error('Error saving data', error);
                    }
                } else {
                    this.$router.push({ name: 'Login'})
                }
            },
            
            async deletePartner() {
                const confirmed = confirm("Are you sure you want to delete the bank information?");
                if (confirmed) {
                    this.deletePartnerOk()
                }
            },

            async deletePartnerOk() {
                const curUser = useUserStore() 
                const host = curUser.apiServer 
                this.detData.bnkId = this.selectedBank
                this.detData.payType = this.selectedServ

                if ((curUser.name!='') && (curUser.secLevel ==1)) {
                    const url   = host+'/prtDel'
                    const param =   {   method: 'POST',
                                        headers: {
                                            'Content-Type': 'application/json',
                                            'usr': curUser.name,
                                            'prt': this.detData.prtId
                                        },
                                    }
                    try {                       
                        const response = await fetch(url, param);
                        if (!response.ok) {
                            throw new Error(`HTTP error! Status: ${response.status}`);
                        } else {                    
                            const rowData = await response.json();
                            console.log(rowData)
                            document.getElementById('errMsg').style.display = 'block'
                            if (rowData.Response=='SUCCESS') {
                                document.getElementById('errMsg').style.color = 'Blue'
                            } else {
                                document.getElementById('errMsg').style.color = 'Red'
                            }
                            document.getElementById('errMsg').innerHTML = rowData.Msg

                            document.getElementById("showList").style.display = "block"
                            document.getElementById("showDetails").style.display = "none"
                            this.fetchData()                        
                        }
                    } catch (error) {
                        console.error('Error saving data', error);
                    }
                } else {
                    this.$router.push({ name: 'Login'})
                }
            },
        }

    }
</script>

<style>
    .toPay {
    background-color: lightgreen; /* Change this to the color you prefer */
}

    /* .lyt_div { 
        min-Width:500px;
        float: none;
        line-height: 40px;
        margin-left: 10px;
    } */

    button {
        font-size:15px;
        color: white;
        background: #047857;
        margin: 0 10px;
        padding: 15px;
        border: none;
        border-radius: 20px;
    }

    .btn:focus {
    outline: 1px solid black;
    }

    .btn:hover {
    color: white;
    background-color: #47980E;
    background-image: linear-gradient(#ecf0f1 ,#47980E);
    }

</style>