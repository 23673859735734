
<template>
  <li>
    <div
      :class="{ bold: isFolder }"
      @click="toggle">

      <template v-if="model.to">
        <router-link :to="{ name: model.to }">{{ model.name }}</router-link>
      </template>
      <template v-else>
        {{ model.name }}
      </template>
      <span v-if="isFolder">[{{ isOpen ? '-' : '+' }}]</span>
    </div>
    <ul v-show="isOpen" v-if="isFolder">
      <template v-for="child in model.children" :key="child.name">
        <TreeItem class="item" :model="child" :depth="depth + 1"></TreeItem>
      </template>
      <!-- <li class="add" @click="addChild">+</li> -->
    </ul>
  </li>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import { useRouter } from 'vue-router'

const props = defineProps({
  model: Object,
  depth: {
    type: Number,
    default: 0
  }
})

// Initialize isOpen based on depth
const isOpen = ref(props.depth === 0)

const isFolder = computed(() => {
  return props.model.children && props.model.children.length
})

// Watch for depth change and update isOpen
watch(() => props.depth, (newDepth) => {
  isOpen.value = newDepth === 0
})

function toggle() {
  isOpen.value = !isOpen.value
}

</script>



<style scoped>
.item {
  cursor: pointer;
  line-height: 1.5;
}
.bold {
  font-weight: bold;
}
.add {
  cursor: pointer;
  color: blue;
}
</style>
