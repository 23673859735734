<template>
  <div style="float:left;margin:20px 0 0 70px;width:700px;border: 0px solid black;">   
  <!-- <div style="width:700px">       -->
        <div class="lyt_div">
            <label class="lyt_label" style="margin-top:20px;font-size:20px;font-weight: bold;width:90%" >API Server</label>
        </div>        
        <div class="lyt_div">
            <label for="apiInterval" class="lyt_label" style="width:180px;">Check Interval (minutes)</label>
            <input id="apiInterval" v-model="sysInfo.apiInterval" type="text" class="lyt_input" style="padding-left:5px;" required/>
        </div>       
        <div class="lyt_div">
            <label for="apiHost" class="lyt_label" style="width:180px;">Host</label>
            <input id="apiHost" v-model="sysInfo.apiHost" type="text" class="lyt_input" style="padding-left:5px;" required/>
        </div>
        <div class="lyt_div">
            <label for="apiPort" class="lyt_label" style="width:180px" >Port</label>
            <input id="apiPort" v-model="sysInfo.apiPort" type="text" class="lyt_input" style="padding-left:5px;" required/>
        </div>
        <div class="lyt_div">
            <label class="lyt_label" style="margin-top:20px;font-size:20px;font-weight: bold;width:90%" >Mail Server</label>
        </div>        
        <div class="lyt_div">
            <label for="mailUser" class="lyt_label" style="width:180px" >User</label>
            <input id="mailUser" v-model="sysInfo.mailUser" type="text" class="lyt_input" style="padding-left:5px;" required/>
        </div>
        <div class="lyt_div">
            <label for="mailPswd" class="lyt_label" style="width:180px" >Password</label>
            <input id="mailPswd" v-model="sysInfo.mailPswd" type="text" class="lyt_input" style="padding-left:5px;" required/>
        </div>
        <div class="lyt_div">
            <label for="mailHost" class="lyt_label" style="width:180px" >Host</label>
            <input id="mailHost" v-model="sysInfo.mailHost" type="text" class="lyt_input" style="padding-left:5px;" />
        </div>
        <div class="lyt_div">
            <label for="mailPort" class="lyt_label" style="width:180px" >Port</label>
            <input id="mailPort" v-model="sysInfo.mailPort" type="text" class="lyt_input" style="padding-left:5px;" required/>
        </div>
        <div class="lyt_div">
            <label for="mailpath" class="lyt_label" style="width:180px" >Local storage path</label>
            <input id="mailpath" v-model="sysInfo.mailPath" type="text" class="lyt_input" style="padding-left:5px;" required/>
        </div>
        <div class="lyt_div">
            <label class="lyt_label" style="margin-top:20px;font-size:20px;font-weight: bold;width:90%" >SFTP Server</label>
        </div>        
        <div class="lyt_div">
            <label for="sftpInterval" class="lyt_label" style="width:180px">Check Interval (minutes)</label>
            <input id="sftpInterval" v-model="sysInfo.sftpInterval" type="text" class="lyt_input" style="padding-left:5px;" required/>
        </div>
        <div class="lyt_div">
            <label for="sftpInput" class="lyt_label" style="width:180px" >Input path</label>
            <input id="sftpInput" v-model="sysInfo.sftpInput" type="text" class="lyt_input" style="padding-left:5px;" required/>
        </div>
        <div class="lyt_div">
            <label for="sftpOutput" class="lyt_label" style="width:180px" >Output path</label>
            <input id="sftpOutput" v-model="sysInfo.sftpOutput" type="text" class="lyt_input" style="padding-left:5px;" required/>
        </div>
    </div>
    <div style="float:left;margin-left:85px;width:65%;border: 0px solid black;">
        <p id='errMsg' style="float:left;color:red;text-align:left;padding:0px;margin:10px 0px 20px 15px">&nbsp;</p>
    </div>
    <div style="float:left;width:65%; text-align: left; border: 0px solid black;">
        <button class='btn' style="width:150px;margin-left:300px" @click="prmInfoSave">Save</button>
    </div>
</template>

<script>
    import { useUserStore } from '../stores/userStore'

    export default {
        name:'SetParam',
        data() {
            return {
                sysInfo: {
                    // infApi
                    apiInterval:'',
                    apiHost: '',
                    apiPort:'',
                    // infMail
                    mailUser:'',
                    mailPswd:'',
                    mailHost:'',
                    mailPort:'',
                    mailPath:'',  // mail console directory
                    // infSFTP
                    sftpInterval:'',
                    sftpInput:'',
                    sftpOutput:''
                },
            }
        },
        mounted() {            
            document.getElementById("formTitle").innerHTML = "Parameter settings"
            const curUser = useUserStore()
            if ((curUser.name=='') || (curUser.secLevel > 1)) {
                this.$router.push({ name: 'Login'})
            } else {
                this.fetchData()
            }
        },
        methods: {            
            async fetchData() {
                // document.getElementById("errMsg").style.display = "none"
                const curUser = useUserStore()
                const host = curUser.apiServer
                try {
                    const param = { method: 'POST',
                                    headers: {'Content-Type': 'application/json',
                                        'usr':curUser.name
                                    },
                                }
                    const response = await fetch(host+'/prmInfoGet', param);
                    if (response.ok) {
                        const rowData = await response.json();
                        console.log(rowData)
                        if (rowData.ok) {
                            this.sysInfo = rowData.dat
                        } else {
                            document.getElementById('errMsg').style.color = 'Red'
                            document.getElementById('errMsg').innerHTML = rowData.msg
                        }
                    } else {
                        document.getElementById('errMsg').style.color = 'Red'
                        document.getElementById('errMsg').innerHTML = `HTTP error! Status: ${response.status}`
                    }
                } catch (error) {
                    document.getElementById('errMsg').style.color = 'Red'
                    document.getElementById('errMsg').innerHTML = error.message
                }
            },

            async prmInfoSave() {
                const confirmed = confirm("Are you sure you want to save the operation information?");
                if (confirmed) {
                    this.prmInfoSaveOK()
                }
            },

            async prmInfoSaveOK() {
                const curUser = useUserStore() 
                const host = curUser.apiServer   
                const url   = host+'/prmInfoSave'
                const param =   {   method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        'userid': curUser.name
                                    },
                                    body: JSON.stringify(this.sysInfo)
                                }
                try {                       
                    const response = await fetch(url, param);
                    if (!response.ok) {
                        document.getElementById('msg').style.color = 'Red'
                        document.getElementById('errMsg').innerHTML = `HTTP error! Status: ${response.status}`
                    } else {                    
                        const rowData = await response.json();
                        document.getElementById('errMsg').style.display = 'block'
                        if (rowData.ok) {
                            document.getElementById('errMsg').style.color = 'blue'
                            document.getElementById('errMsg').innerHTML = rowData.msg
                        } else {
                            document.getElementById('errMsg').style.color = 'Red'
                            document.getElementById('errMsg').innerHTML = rowData.msg
                        }
                    }
                } catch (error) {
                    document.getElementById('errMsg').style.color = 'Red'
                    document.getElementById('errMsg').innerHTML = error.message
                }
            },
        }


    }
</script>

<style>

</style>