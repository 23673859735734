<template> 
<div id='app'>
  <div style="display: flex; justify-content: flex-start; align-items: center; height: 10vh; width:100%; border: 01px solid black;">
    <img alt="logo" class="logo" style="margin: 50px;" src="./assets/logo.png">
  </div>
  
  <div v-if="!$route.meta.hideMenu" id="menu" style="display: flex; float:left; text-align: left; height:88vh; width:300px; min-width:250px ; border: 1px solid black;">
      <TreeView />
    </div>
  
  <div id="header" class="hdr" style="display: flex; float:left;height:10vh;width:50%;margin:0px;border: 0px solid black;">
    <h1 id="formTitle" style="font-size:50px;text-align: left;margin:20px 0px 0px 100px;border: 0px solid black;">Title</h1> 
  </div>
  <router-view/>
  <Footer />
</div>
</template>


<script>
    import TreeView from './components/TreeView.vue'
    import Footer from './components/Footer.vue'

    export default {
        data() {
            return {
              formTitle : "start",
              pageWidth : ""
            }
        },
        components: {
          TreeView,Footer
        },
        mounted() {
          window.addEventListener('resize', this.updatePageWidth);
        },
        methods: {
          updatePageWidth() {
            this.pageWidth = window.innerWidth-300;
          }
        }
    }
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.item {
  cursor: pointer;
  line-height: 1.5;
}
.bold {
  font-weight: bold;
}

</style>
