<template>
    <div id="showList" style="float:left;width:60%;border: 0px solid black;">
        
        <div class="lyt_div">
            <label for="mstRef" class="lyt_label" style="float:left;text-align: left;width:200px;font-size: 18px;margin: 0px 20px 20px 30px;">Master Reference</label>
            <input id="mstRef" v-model="mstRef"  type="text" class="lyt_input" style="width:40%;padding-left:10px;" @keydown="handleKeyDown" @blur="handleChgMstRef" required/>
        </div>

        <ag-grid-vue
            class="ag-theme-alpine"
            style="float:left;height: 50vh; width: 100%; margin-left: 10px;"
            :columnDefs="columnDefs"
            :rowData="rowData"
            @cellDoubleClicked="onCellDoubleClicked"
            :defaultColDef="{ cellClassRules: cellClassRules }"
            :overlayLoadingTemplate="overlayLoadingTemplate"
            :overlayNoRowsTemplate="overlayNoRowsTemplate"
        >
        </ag-grid-vue>        
        <!-- this hould be inside the grid-vue :rowSelection="rowSelection" -->
        <div style="float:left;height:60px;width:99%;margin:20px 0 0 0;">
            <!-- <div style="float:left;height:55px;width:380px;margin:-5px 0px 0px 10px;border-radius: 20px;border: 0px solid black;">
                <input id="pswd" v-model="userPass" class="lyt_input" type="password" style="border-radius: 10px;line-height:30px;margin:12px 0 0 25px;width:160px" placeholder=" Enter your password" required/>
                <button class='btn' style="margin-top:4px;width:150px;float:left" @click="requestKey">RequestKey</button>
            </div> -->
            <button class='btn' style="margin-left:100px;width:150px;float:left" :disabled="disableSubmit" :class="{ 'disabled-button': disableSubmit }" @click="trxSubmit">Submit</button>
            <button class='btn' style="width:150px;float:left" @click="dataRefresh">Refresh</button>
            <button class='btn' style="width:150px;float:left" :disabled="disableResend" :class="{ 'disabled-button': disableResend }" @click="btnNewOts">CreateNew</button>
        </div>
        <div style="float:left;width:99%;">            
            <p id='errMsg' style="color:red;text-align:left;padding:0px;margin:10px 0px 0px 15px">&nbsp;</p>
            <p style="text-align:left;padding:0px;margin:15px 0px 0px 15px">Note</p>            
            <p style="text-align:left;padding:0px;margin:0px 0px 0px 15px">After entering the master reference, press the Tab or Enter key to get outstanding transaction listing</p>
            <p style="text-align:left;padding:0px;margin:0px 0px 0px 15px">Click on Resend to create new transfer as most banks does not accept duplicate keys from previous transfers</p>
            <!-- <p style="text-align:left;padding:0px;margin:0px 0px 0px 15px">Enter your password and click on RequestKey to get authorization key emailed to your registered email account</p> -->
            <p style="text-align:left;padding:0px;margin:0px 0px 0px 15px">Double click on transfer line to select transfer</p>
            <p style="text-align:left;padding:0px;margin:0px 0px 0px 15px">Please do not use the browser refresh or you will be logged out. Use the application refresh above instead</p>
        </div>
    </div>
    <div id='showAppr' style="width:55%" hidden>   
        <div class="lyt_div">
            <label for="refDet" class="lyt_label" style="width:15%" >Details Reference</label>
            <input id="refDet" v-model="saveData.refDet"  type="text" class="lyt_input" style="width:40%;padding-left:10px;" disabled />
        </div>
        <br/>
        <div>
            <div class="lyt_div">
                <label for="benAcct" class="lyt_label" style="width:15%">Bene. Bank Acct <span style="color:red">*</span></label>
                <input id="benAcct" v-model="saveData.benAcct" type="text" class="lyt_input" style="width:40%;padding-left:10px;" required/>
            </div>
        </div>    
        <br/>   
        <div>
            <div class="lyt_div">
                <label for="ccy" class="lyt_label" style="width:15%">Currency <span style="color:red">*</span></label>
                <input id="ccy" v-model="saveData.ccy" type="text" class="lyt_input" style="width:40%;padding-left:10px;" required/>
            </div>
        </div>    
        <br/>   
        <div class="lyt_div">
            <label for="amt" class="lyt_label" style="width:15%" >Amount <span style="color:red">*</span></label>
            <input id="amt" v-model="saveData.amt" type="text" class="lyt_input" style="width:40%;padding-left:10px;" required/>
        </div> 
        <br/>    

        <div>
            <div class="lyt_div">
                <label for="descr" class="lyt_label" style="width:15%" >Description <span style="color:red">*</span></label>
                <input id="descr" v-model="saveData.descr" type="text" class="lyt_input" style="width:40%;padding-left:10px;" required/>
            </div>
        </div>     
        <br/>        
        <div>
            <div class="lyt_div">
                <label for="resRmks" class="lyt_label" style="width:15%" >Bank Remarks</label>
                <input id="resRmks" v-model="saveData.resRmks" type="text" class="lyt_input" style="width:40%;padding-left:10px;" disabled />
            </div>
        </div>  
        <br/> 
        <!-- <div class="lyt_div">
            <label for="authKey" class="lyt_label" style="width:15%">AuthKey <span style="color:red">*</span></label>
            <input id="authKey" v-model="authKey" class="lyt_input" style="padding-left:10px;width:40%;" type="password" placeholder="Enter auth key" required/>
        </div> -->
        <!-- <br/><br/><br/><br/><br/><br/><br/><br/> --> 
        <br/>    
        <div style="float:left;height:50px;margin:20px 0 0 0">
            <button class='btn' style="width:150px;margin:25px 0 0 270px;" @click="trxSave" :disabled="isSave">Save</button>
            <button class='btn' style="width:150px;" @click="closeAppr">Close</button>
        </div>
        <p id='errMsg2' style="color:red;text-align:left;padding:0px;margin:10px 0px 0px 15px">&nbsp;</p>
    </div>

</template>

<script>
    import { useUserStore } from '../stores/userStore'
    // import { AgGridVue } from 'ag-grid-vue'
    import { AgGridVue } from 'ag-grid-vue3'
    import 'ag-grid-community/styles/ag-grid.css'
    import 'ag-grid-community/styles/ag-theme-alpine.css'
    // import { GROUP_AUTO_COLUMN_ID } from 'ag-grid-community'
    // import TreeView from '../components/TreeView.vue'

    export default {
        name: 'TrxStfAmd', 
        components:{
            AgGridVue, 
            // TreeView
        },
        data() {
            return {
                mstRef:null,
                mstRefOld:null,
                isSave: false, 
                disableSubmit:true,
                disableResend:true,
                isOts:false,
                selectedCust: null,
                selectedServ: null,
                optionsCust: [],
                optionsServ: [],

                isPasswordVisible: false,
                // isPasswordVisible: true,
                userPass: "",
                loginData: {
                    customerCode:'',
                    serviceCode: ''
                },
                formTitle : "Amend Transfer",
                columnDefs:[
                    {headerName:'Details Ref',  field:'refDet',     width:120, cellStyle: { textAlign: 'left' }},
                    {headerName:'Bene Acct',    field:'benAcct',    width:200, cellStyle: { textAlign: 'left' }},
                    {headerName:'Ccy',          field:'ccy',        width:80, cellStyle: { textAlign: 'left' }},
                    {headerName:'Amount',       field:'amt',        width:150, cellStyle: { textAlign: 'right'}, valueFormatter: this.currencyFormatter},
                    {headerName:'Description',  field:'descr',      width:200, cellStyle: { textAlign: 'left' }},
                    {headerName:'BankStatus',   field:'resStat',    width:150, cellStyle: { textAlign: 'left'}},
                    {headerName:'Bank Remarks', field:'resRmks',    width:200, cellStyle: { textAlign: 'left' }}
                ],

                returnData:null,
                rowData:null,
                mstData:null,

                overlayLoadingTemplate: '<span style="font-size:20px;color:Red">Please enter our master reference key</span>', // Custom loading overlay
                overlayNoRowsTemplate:  '<span style="font-size:20px;color:Blue">No records found</span>', // Custom no rows overlay
      
                authKey:'',
                
                saveData:{
                    refDet:'',
                    benAcct:'',
                    ccy:'',
                    amt:'',
                    descr:'',
                    resStat:'',
                    resRmks:''
                },

                onCellDoubleClicked: event => {
                    if (this.mstData.trxStat==4) {
                        this.createNewOts()
                    } else {
                        document.getElementById("showList").style.display = "none"
                        document.getElementById("showAppr").style.display = "block"
                        this.saveData.refDet = event.data.refDet
                        this.saveData.benAcct = event.data.benAcct
                        this.saveData.ccy = event.data.ccy
                        this.saveData.amt = event.data.amt.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                        this.saveData.descr = event.data.descr
                        this.saveData.resStat = event.data.resStat
                        this.saveData.resRmks = event.data.resRmks
                        this.authKey=''
                        this.isSave=false
                    }
                }
            }
        },
        created() {            
        },
        mounted() {            
            document.getElementById("formTitle").innerHTML = this.formTitle
            const curUser = useUserStore()
            if ((curUser.name=='') || (curUser.secLevel > 2)) {
                this.$router.push({ name: 'Login'})
            // } else {
            //     this.getApprList()
            }
        },
        
        computed: {
            cellClassRules() {
                return {
                    'toPay': params => params.value === 'Pay'
                };
            },
            selectedCustName() {
                const selected = this.optionsCust.find(option => option.prtId === this.selectedCust);
                return selected ? selected.prtName : '';
            },
            selectedServName() {
                const selected = this.optionsServ.find(option => option.payType === this.selectedServ);
                return selected ? selected.payDescr : '';
            },
        },

        methods: {
            clearDataTable() {

            },

            handleKeyDown(event) {
                if (event.key === 'Enter') {
                     this.handleChgMstRef()
                }
            },

            closeAppr() {
                document.getElementById("showList").style.display = "block"
                document.getElementById("showAppr").style.display = "none"
                this.fetchData()
            },

            async dataRefresh() {
                document.getElementById("errMsg").style.display = "none"
                this.fetchData()
            },

            async fetchData() {                
                const curUser = useUserStore()
                const host = curUser.apiServer
                this.loginData.customerCode = curUser.prtId
                this.loginData.serviceCode = this.selectedServ
                if ((curUser.name!='') && (curUser.secLevel <= 2)) {
                    try {
                        const param = { method: 'POST',
                                        headers: {'Content-Type': 'application/json',
                                            'usr':curUser.name,
                                            'ref':this.mstRef
                                        },
                                        // body: JSON.stringify(this.loginData)
                                    }
                        const response = await fetch(host+'/trxGetOts', param);
                        const returnData = await response.json();
                        this.mstData = returnData[0].mst                    
                        this.rowData = returnData[0].det
                        if (this.mstData.trxStat==4) { 
                            this.isOts=true 
                            this.disableSubmit=true
                            this.disableResend=false
                            document.getElementById('errMsg').style.display = 'none'
                        } else if (this.mstData.trxStat==1) { 
                            this.isOts=false 
                            this.disableSubmit=false
                            this.disableResend=true
                        } else if (this.mstData.trxStat==2) { 
                            this.isOts=false 
                            this.disableSubmit=true
                            this.disableResend=true
                            document.getElementById('errMsg').style.display = 'block'
                            document.getElementById('errMsg').style.color = 'Blue'
                            document.getElementById('errMsg').innerHTML = this.mstRef + ": Transfer already submitted. Please use the Approval window to process it."
                        } else if (this.mstData.trxStat==5) { 
                            this.isOts=false 
                            this.disableSubmit=true
                            this.disableResend=true
                            document.getElementById('errMsg').style.display = 'block'
                            document.getElementById('errMsg').style.color = 'Blue'
                            document.getElementById('errMsg').innerHTML = this.mstRef + ": Transfer already processed. Please use the view window to look at it."
                        } else {
                            this.isOts=false 
                            this.disableSubmit=true
                            this.disableResend=true
                            document.getElementById('errMsg').style.display = 'none'
                        }
                    } catch (error) {
                        console.error('Error fetching data', error);
                    }
                } else {
                    this.$router.push({ name: 'Login'})
                }
                
            },
            
            async trxSave() {
                const confirmed = confirm("Are you sure you want to save the transaction?");
                if (confirmed) {
                    this.isSave=true
                    this.trxSaveOK()
                }

            },
            
            async trxSaveOK() {
                const curUser = useUserStore()
                const host = curUser.apiServer
                if ((curUser.name!='') && (curUser.secLevel <= 2)) {
                    const url   = host+'/trxSave'
                    const param =   {   method: 'POST',
                                        headers: {
                                            'Content-Type': 'application/json',
                                            'usr': curUser.name,
                                        },
                                        body: JSON.stringify(this.saveData)
                                    }
                    try {                       
                        const response = await fetch(url, param);
                        if (!response.ok) {
                            throw new Error(`HTTP error! Status: ${response.status}`);
                        } else {                    
                            const rowData = await response.json();
                            document.getElementById('errMsg').style.display = 'block'
                            if (rowData.Response=='SUCCESS') {
                                document.getElementById('errMsg').style.color = 'Blue'
                            } else {
                                document.getElementById('errMsg').style.color = 'Red'
                            }
                            document.getElementById('errMsg').innerHTML = rowData.OurRef + ": " + rowData.Remarks

                            document.getElementById("showList").style.display = "block"
                            document.getElementById("showAppr").style.display = "none"
                            this.fetchData()                        
                        }
                    } catch (error) {
                        console.error('Error fetching data', error);
                    }
                } else {
                    this.$router.push({ name: 'Login'})
                }
            },

            async trxSubmit() {
                const confirmed = confirm("Are you sure you want to submit the batch for approval?");
                if (confirmed) {
                    this.isDisabled=true
                    this.trxSubmitOK()
                }

            },
            
            async trxSubmitOK() {
                const curUser = useUserStore()
                const host = curUser.apiServer
                if ((curUser.name!='') && (curUser.secLevel <= 2)) {
                    const url   = host+'/trxSubmit'
                    const param =   {   method: 'POST',
                                        headers: {
                                            'Content-Type': 'application/json',
                                            'usr': curUser.name,
                                            'ref':this.mstRef
                                        },
                                        // body: JSON.stringify(this.saveData)
                                    }
                    try {                       
                        const response = await fetch(url, param);
                        if (!response.ok) {
                            throw new Error(`HTTP error! Status: ${response.status}`);
                        } else {                    
                            const rowData = await response.json();
                            document.getElementById('errMsg').style.display = 'block'
                            if (rowData.Response=='SUCCESS') {
                                document.getElementById('errMsg').style.color = 'Blue'
                                document.getElementById('errMsg').innerHTML = rowData.OurRef + " : " + rowData.Remarks
                                this.rowData = []
                                this.mstRef = null
                                this.mstRefOld = null
                            } else {
                                document.getElementById('errMsg').style.color = 'Red'
                                document.getElementById('errMsg').innerHTML = rowData.OurRef + " : " + rowData.Remarks
                            }

                            document.getElementById("showList").style.display = "block"
                            document.getElementById("showAppr").style.display = "none"
                                                    
                        }
                    } catch (error) {
                        console.error('Error fetching data', error);
                    }
                } else {
                    this.$router.push({ name: 'Login'})
                }
            },

            async btnNewOts() {
                const confirmed = confirm("Create new resend transfer for "+this.mstRef+". Are you sure?");
                if (confirmed) {
                    this.isSave=true
                    this.createNewOtsOK()
                }

            },

            async createNewOts() {
                const confirmed = confirm("This batch transfer has failed transfer. Create new transfer for "+this.mstRef+"?");
                if (confirmed) {
                    this.isSave=true
                    this.createNewOtsOK()
                }

            },

            async createNewOtsOK() {
                const curUser = useUserStore()
                const host = curUser.apiServer
                if ((curUser.name!='') && (curUser.secLevel <= 2)) {
                    const url   = host+'/trxCrRsd'
                    const param =   {   method: 'POST',
                                        headers: {
                                            'Content-Type': 'application/json',
                                            'usr': curUser.name,
                                            'ref':this.mstRef
                                        },
                                        // body: JSON.stringify(this.saveData)
                                    }
                    try {                       
                        const response = await fetch(url, param);
                        if (!response.ok) {
                            throw new Error(`HTTP error! Status: ${response.status}`);
                        } else {                    
                            const rowData = await response.json();
                            document.getElementById('errMsg').style.display = 'block'
                            if (rowData.Response=='SUCCESS') {
                                document.getElementById('errMsg').innerHTML = 'Create as '+rowData.newRef+'. Please use this instead of '+this.mstRef+' in future'
                                this.mstRef = rowData.newRef
                                document.getElementById('errMsg').style.color = 'Blue'
                            } else {
                                document.getElementById('errMsg').style.color = 'Red'
                                document.getElementById('errMsg').innerHTML = this.mstRef + ": " + rowData.Remarks
                            }

                            document.getElementById("showList").style.display = "block"
                            document.getElementById("showAppr").style.display = "none"
                            this.fetchData()                        
                        }
                    } catch (error) {
                        console.error('Error fetching data', error);
                    }
                } else {
                    this.$router.push({ name: 'Login'})
                }

            },
            
            async handleChgMstRef() {
                if (this.mstRef != this.mstRefOld) {
                    const confirmed = confirm("Are you sure you want to retrieve\noutstanding transaction for "+this.mstRef+"?");
                    if (confirmed) {
                        this.isSave=true                        
                        document.getElementById('errMsg').style.display = 'none'
                        this.fetchData()
                    }
                    this.mstRefOld = this.mstRef
                } 


            },

            logoutSession() {
                const curUser = useUserStore()
                curUser.clearUser()
                this.$router.push({ name: 'Login'})
            },

            currencyFormatter(params) {
                // Format salary to include '$' symbol and thousand separator
                // return params.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                // return params.value.toFixed(2);
                return params.value.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})


            },
            
            countFormatter(params) {
                // Format salary to include '$' symbol and thousand separator
                // return params.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                return params.value.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})
            },


        }

    }
</script>

<style>
    .toPay {
    background-color: lightgreen; /* Change this to the color you prefer */
}

    /* .lyt_div { 
        min-Width:500px;
        float: none;
        line-height: 40px;
        margin-left: 10px;
    } */

    button {
        font-size:15px;
        color: white;
        background: #047857;
        margin: 0 10px;
        padding: 15px;
        border: none;
        border-radius: 20px;
    }

    .btn:focus {
    outline: 1px solid black;
    }

    .btn:hover {
    color: white;
    background-color: #47980E;
    background-image: linear-gradient(#ecf0f1 ,#47980E);
    }

</style>