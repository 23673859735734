<template>
  <div style="float:left;margin:20px 0 0 70px;width:700px;border: 0px solid black;">   
  <!-- <div style="width:700px">       -->
        <div>
            <div class="lyt_div">
                <label for="bankName" class="lyt_label">Bank Name</label>
                <input id="bankName" v-model="bnkInfo.name" type="text" class="lyt_input" required/>
            </div>
        </div>        
        <div>
            <div class="lyt_div">
                <label for="short" class="lyt_label" >Short Name</label>
                <input id="short" v-model="bnkInfo.short" type="text" class="lyt_input" required/>
            </div>
        </div>        
        <div>
            <div class="lyt_div">
                <label for="contact" class="lyt_label" >Contact Person</label>
                <input id="contact" v-model="bnkInfo.contact" type="text" class="lyt_input" required/>
            </div>
        </div>    
        <div>
            <div class="lyt_div">
                <label for="mobile" class="lyt_label" >Contact Mobile</label>
                <input id="mobile" v-model="bnkInfo.mobile" type="text" class="lyt_input" />
            </div>
        </div>    
        <div>
            <div class="lyt_div">
                <label for="tel" class="lyt_label" >Contact Telephone</label>
                <input id="tel" v-model="bnkInfo.tel" type="text" class="lyt_input" />
            </div>
        </div>  
        <div>
            <div class="lyt_div">
                <label for="email" class="lyt_label" >Contact Email</label>
                <input id="email" v-model="bnkInfo.email" type="text" class="lyt_input" required/>
            </div>
        </div>  
    </div>
    <div style="float:left;width:65%;border: 0px solid black;">
        <p id='errMsg' style="float:left;color:red;text-align:left;padding:0px;margin:10px 0px 20px 15px">&nbsp;</p>
    </div>
    <div style="float:left;width:65%; text-align: left; border: 0px solid black;">
        <button class='btn' style="width:150px;margin-left:18%" @click="submitNewBank">Submit</button>
    </div>
</template>

<script>
    import { useUserStore } from '../stores/userStore'

    export default {
        name:'BankSetup',
        data() {
            return {
                bnkInfo: {
                    name: '',
                    short:'',
                    contact:'',
                    mobile:'',
                    tel:'',
                    email:'',
                },
            }
        },
        mounted() {            
            document.getElementById("formTitle").innerHTML = "Setup New Bank Information"
            const curUser = useUserStore()
            if ((curUser.name=='') || (curUser.secLevel > 1)) {
                this.$router.push({ name: 'Login'})
            }
        },
        methods: {
            async submitNewBank() {
                const curUser = useUserStore() 
                const host = curUser.apiServer   
                if ((curUser.name!='') && (curUser.secLevel <=1)) {
                    const url   = host+'/bnkNew'
                    const param =   {   method: 'POST',
                                        headers: {
                                            'Content-Type': 'application/json',
                                            'userid': curUser.name
                                        },
                                        body: JSON.stringify(this.bnkInfo)
                                    }
                    try {                       
                        const response = await fetch(url, param);
                        if (!response.ok) {
                            throw new Error(`HTTP error! Status: ${response.status}`);
                        } else {                    
                            const rowData = await response.json();
                            document.getElementById('errMsg').style.display = 'block'
                            if (rowData.Response=='SUCCESS') {
                                document.getElementById('errMsg').style.color = 'Blue'
                                this.bnkInfo.name = ''
                                this.bnkInfo.short = ''
                                this.bnkInfo.contact = ''
                                this.bnkInfo.mobile = ''
                                this.bnkInfo.tel = ''
                                this.bnkInfo.email = ''
                            } else {
                                document.getElementById('errMsg').style.color = 'Red'
                            }
                            document.getElementById('errMsg').innerHTML = rowData.Msg
                        }
                    } catch (error) {
                        console.error('Error fetching data', error);
                    }
                } else {
                    this.$router.push({ name: 'Login'})
                }
            },
        }


    }
</script>

<style>

</style>